import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, Select } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const CreatePermissionModal = ({ _this }) => {
  return (
    <Modal
      show={_this.createPermissionModalVisibility}
      onHide={() => _this.setCreatePermissionModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Permission</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setCreatePermissionModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <Input
            label="Permission Name"
            placeholder="@Enter Permission Name"
            type="text"
            required
            value={_this.createPermissionData?.permission_name || ''}
            onChange={(e) => _this.updateCreatePermissionData('permission_name', e.target.value)}
          />

          <Dropdown
            label="Permission Group"
            required
            type="text"
            options={_this.permissionGroupOption}
            // isClearable
            aria-label="Default select example"
            onChange={(e) => {
              _this.updateCreatePermissionData('permission_group', e);
            }}
            value={_this.createPermissionData?.permission_group}
            // isMulti
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.submitCreatePermissionData();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePermissionModal;
