import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import moment from 'moment';
import { FaPlus, FaPlusCircle, FaPlusSquare } from 'react-icons/fa';
import { BiPlus, BiSend } from 'react-icons/bi';
const PayExtra = ({ _this }) => {
  return (
    <Modal
      className="mt-4"
      show={_this.payModal}
      onHide={() => {
        _this.setPayModal(false);
      }}
      onExit={() => {
        _this.setPayExtraAmount('');
        _this.setNextDueDate('');
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="flex justify-between">
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
          Pay in Advance
        </Modal.Title>
        <div className="flex gap-2 items-center">
          <h3 className="text-md">
            Patient Name :
            <strong>
              {_this.customerDetails?.first_name + ' ' + _this.customerDetails.last_name}
            </strong>
          </h3>
          <AiFillCloseCircle
            size={30}
            onClick={() => {
              _this.setPayModal(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="m-0">
        <Card className=" ">
          <div className="">
            <Input
              label="Pay Amount "
              value={_this.payExtraAmount}
              onChange={(e) => _this.setPayExtraAmount(e.target.value)}
              type="number"
            />
            <Input
              className="mb-3"
              label="Next Due Date"
              type="date"
              value={_this.nextDueDate}
              onChange={(e) => _this.setNextDueDate(e.target.value)}
            />
            <Button onClick={_this.handelPayExtra}>Pay</Button>
          </div>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default PayExtra;
