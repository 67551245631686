import React from 'react';
import { Card } from 'components';
import { BsCardHeading, BsCardChecklist, BsBank } from 'react-icons/bs';
import { GrAggregate } from 'react-icons/gr';
import { FaPaypal } from 'react-icons/fa';
import { currencyFormatter } from 'services/helpers';

const Body = ({ _this }) => {
  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="relative flex flex-col lg:flex-row gap-2 justify-between items-center">
          <h3 className="text-body-color text-xl font-semibold pb-2">Chargeback Dashboard</h3>

          <div className="flex flex-col justify-start items-start px-2 bg-white cardShadow rounded-md w-60">
            <div className="mt-1 flex flex-row justify-between gap-2 items-center border-b-[1px] py-2">
              <FaPaypal size={40} className="text-button-primaryHover" />
              <div>
                <h5 className="text-slate-800 text-2xl">
                  {currencyFormatter.format(_this.data?.recoveredData?.amount)}
                </h5>
                <h5 className="text-slate-500 text-sm">Recovered Revenue</h5>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className="flex justify-between gap-3 flex-wrap">
        {/******************  All Disputes  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <BsCardChecklist size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">All Disputes Till Date</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.allDisputes?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.allDisputes?.amount)}
            </h5>
          </div>
        </div>

        {/******************  Active Disputes  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <BsCardHeading size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">Active Disputes</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.activeData?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.activeData?.amount)}
            </h5>
          </div>
        </div>

        {/******************  Disputes Recovered  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <BsCardChecklist size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">Disputes Recovered</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.recoveredData?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.recoveredData?.amount)}
            </h5>
          </div>
        </div>

        {/******************  Under Review  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <BsBank size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">Under Review</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.underReviewData?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.underReviewData?.amount)}
            </h5>
          </div>
        </div>

        {/******************  Evidence Submitted  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <GrAggregate size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">Evidence Submitted</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.hasEvidenceData?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.hasEvidenceData?.amount)}
            </h5>
          </div>
        </div>

        {/******************  Lost Disputes  *********************/}
        <div className="flex flex-col justify-start items-start px-3 bg-white cardShadow rounded-md w-72 my-3">
          <div className="mt-1 flex flex-row justify-between gap-2 items-center py-2">
            <div className="w-12 h-10 bg-blue-400/[0.6] flex justify-center items-center rounded-md">
              <BsCardChecklist size={30} className="text-button-primaryHover" />
            </div>
            <h5 className="text-slate-800 text-lg">Disputes Lost</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1">
            <h5 className="text-slate-500 text-sm">Number Of Cases</h5>
            <h5 className="text-slate-800 text-2xl">{_this.data?.lostData?.cases}</h5>
          </div>
          <div className="mt-1 flex flex-col pb-1 mb-2">
            <h5 className="text-slate-500 text-sm">Total Value</h5>
            <h5 className="text-slate-800 text-xl">
              {currencyFormatter.format(_this.data?.lostData?.amount)}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
