import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';

const SubscriptionDetails = ({ _this }) => {
  const subscription = _this.subscriptionDetails;
  return (
    <Modal
      className="mt-10"
      show={_this.subscriptionDetailsModalOpen}
      onHide={() => {
        _this.setSubscriptionDetailsModalOpen(false);
        _this.setSubscriptionDetails(null);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Subscription Details</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setSubscriptionDetailsModalOpen(false);
            _this.setSubscriptionDetails(null);
          }}
        />
      </Modal.Header>

      <Modal.Body className="flex flex-col justify-center items-center">
        <table className="shadow-md rounded-lg bg-white ">
          <thead className="bg-blue-100 border rounded-lg h-12">
            <td></td>
            <td></td>
          </thead>

          <tbody className="border rounded-full">
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 ">Subscription ID </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?._id}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 ">Payment Interval </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.plan_interval}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 ">Price </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                ${subscription?.price}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 ">L Warranty </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.l_warranty_expiry
                  ? moment(subscription?.l_warranty_expiry).format('YYYY-MM-DD')
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 ">R Warranty </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.r_warranty_expiry
                  ? moment(subscription?.r_warranty_expiry).format('YYYY-MM-DD')
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 "> Last Invoice Date </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.active_invoice_date
                  ? moment(subscription?.active_invoice_date).format('YYYY-MM-DD')
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 "> Next Invoice Date </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.next_invoice_date
                  ? moment(subscription?.next_invoice_date).format('YYYY-MM-DD')
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <td className="text-slate-500 text-sm border px-4 py-2 "> Expiry Date </td>
              <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                {subscription?.expiry_date
                  ? moment(subscription?.expiry_date).format('YYYY-MM-DD')
                  : 'N/A'}
              </td>
            </tr>

            {subscription?.cancelled_on ? (
              <tr>
                <td className="text-slate-500 text-sm border px-4 py-2 "> Cancelled On </td>
                <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                  {subscription?.cancelled_on
                    ? moment(subscription?.cancelled_on).format('YYYY-MM-DD')
                    : 'N/A'}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="text-slate-500 text-sm border px-4 py-2 "> Activated On </td>
                <td className="text-slate-500 text-sm capitalize border  px-4 py-2 ">
                  {subscription?.activated_on
                    ? moment(subscription?.activated_on).format('YYYY-MM-DD')
                    : 'N/A'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>

      {subscription && subscription?.status != 'Expired' && subscription?.status != 'Cancelled' && (
        <Modal.Footer>
          <Button color="danger" onClick={() => _this.cancelSubscription(subscription._id)}>
            Cancel Subscription
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default SubscriptionDetails;

//test
