import { useEffect } from 'react';

export const parseHsppId = (id) => {
  if (!id) return '';
  return parseInt(`${id.substring(0, 4)}${id.substring(20)}`, 16).toString();
};

export const renderStatusColorClass = (status) => {
  let colors = 'bg-green-200 text-green-700';
  if (status == 'Cancelled' || status == 'Expired' || status == 'Suspended')
    colors = 'bg-red-200 text-red-700';
  if (status == 'OnHold') colors = 'bg-amber-200 text-amber-700';
  return colors;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const useOnKeyPress = (callback, targetKey) => {
  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === targetKey) {
        callback();
      }
    };
    window.addEventListener('keydown', keyPressHandler);
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [callback, targetKey]);
};

//  temporary for Idaho locations
// don't allow to create customers...
export const idahoLocations = [
  "Coeur D'alene",
  'Nampa',
  'Boise',
  'Eagle',
  'Sandpoint',
  'Meridian',
  'Emmett',
  'Boise ParkCenter',
  'Lewiston',
  'Mccall',
  'SC-Moscow'
];
