import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const RefundDuplicateInvoiceModal = ({ _this }) => {
  return (
    <Modal
      show={_this.refundDuplicateInvoiceModalVisibility}
      onHide={() => _this.setRefundDuplicateInvoiceModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Initiate Duplicate Invoice Refund
        </Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setRefundDuplicateInvoiceModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-col">
        <div className="flex justify-between">
          <h3 className="font-semibold text-sm">
            Name: {_this.customerDetails?.first_name} {_this.customerDetails?.last_name}
          </h3>

          <h3 className="font-semibold text-sm">
            Patient ID: {_this.customerDetails?.customer_id}
          </h3>
        </div>
        <Card className="mb-3 flex mr-2">
          <Input
            label="Amount"
            placeholder="@Enter"
            type="text"
            required
            value={_this.refundDuplicateInvoiceDetails?.amount}
            onChange={(e) =>
              _this.setRefundDuplicateInvoiceDetails((prev) => ({
                ...prev,
                amount: e.target.value
              }))
            }
          />
          <Input
            label="Reason"
            placeholder="@Enter"
            type="text"
            required
            value={_this.refundDuplicateInvoiceDetails?.reason}
            onChange={(e) =>
              _this.setRefundDuplicateInvoiceDetails((prev) => ({
                ...prev,
                reason: e.target.value
              }))
            }
          />
          <Input
            label="Transaction ID"
            placeholder="@Enter"
            type="text"
            required
            value={_this.refundDuplicateInvoiceDetails?.payment_gateway_transaction_id}
            onChange={(e) =>
              _this.setRefundDuplicateInvoiceDetails((prev) => ({
                ...prev,
                payment_gateway_transaction_id: e.target.value
              }))
            }
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.initiateDuplicateInvoiceRefund();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefundDuplicateInvoiceModal;
