import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const chargebackDashboard = {
  getChargebackDashboardReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        '/v1/chargeback/get-dashboard-data',

        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default chargebackDashboard;
