import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Card } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const CheckCustomerExistModal = ({ _this }) => {
  return (
    <Modal
      show={_this.checkCustomerExistModalVisibility}
      onHide={() => _this.setCheckCustomerExistModalVisibility(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Alert</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setCheckCustomerExistModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          {!_this.checkcustomerExistHspp_id?.email ? (
            // Check if email doesn't exist
            <>
              {!_this.checkcustomerExistHspp_id?.phone ? (
                // Check if phone doesn't exist
                <h3 className="text-font-label text-xl">
                  This patient is already registered with us. Please click{' '}
                  <Link
                    to={`/customer-info/${_this.checkcustomerExistHspp_id.customer_id}`}
                    className="text-blue-500 font-medium"
                  >
                    #{_this.checkcustomerExistHspp_id.hspp_id}
                  </Link>{' '}
                  to see their profile
                </h3>
              ) : (
                // Phone number exists
                <h3 className="text-font-label text-xl">
                  This phone number is already taken. Please enter a different phone number.
                </h3>
              )}
            </>
          ) : (
            // Email address exists
            <h3 className="text-font-label text-xl">
              This email address is already taken. Please enter a different email address.
            </h3>
          )}
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default CheckCustomerExistModal;
