import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const months = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'Septeber', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];
  const loggedInUser = useSelector((state) => state.session).userSession;
  const [locationList, setLocationList] = useState(null);
  const [locationListForActiveHsppHistoryReport, setLocationListForActiveHsppHistoryReport] =
    useState(null);
  const [franchiseListForActiveHsppHistoryReport, setFranchiseListForActiveHsppHistoryReport] =
    useState(null);
  const [droppedPatientsDetails, setDroppedPatientsDetails] = useState({
    start_due_date: moment().subtract(1, 'days').toDate(),
    end_due_date: moment().toDate(),
    location: []
  });
  const [chargebackDetails, setChargebackDetails] = useState({
    start_date: moment().startOf('month').toDate(),
    end_date: moment().toDate()
  });

  const [activeHsppPatientDetails, setActiveHsppPatientDetails] = useState({
    start_date: moment().subtract(1, 'days').toDate(),
    current_date: moment().toDate(),
    search_by: {
      label: 'Location Name',
      value: 'location_name'
    }
  });

  const [activeHsppPatientHistoryDetails, setActiveHsppPatientHistoryDetails] = useState({
    start_date: moment().subtract(1, 'days').toDate(),
    current_date: moment().toDate(),

    search_field: {
      label: 'All',
      value: 'All'
    },
    search_value: []
  });
  const [newsLetterMonth, setNewsLetterMonth] = useState({
    year: moment().year(),
    month: months[moment().month()]
  });

  const [hsppAttatchmentDetails, setHsppAttatchmentDetails] = useState({
    start_date: moment().startOf('month').toDate(),
    end_date: moment().toDate(),
    search_by: {
      label: 'Location Name',
      value: 'location_name'
    }
  });
  const activeHsppPatientOptions = [
    { label: 'Location Name', value: 'location_name' },
    { label: 'Franchise Name', value: 'franchise_name' },
    { label: 'Location Group', value: 'location_group' },
    { label: 'State', value: 'state' },
    { label: 'Total', value: 'total' }
  ];

  useEffect(() => {
    loadLocationOptions();
  }, []);

  const loadLocationOptions = async () => {
    dispatch(loadingStart());
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          const locationMap = {};

          response.forEach((item) => {
            const { franchiseName, locationId, locationName } = item;
            if (!locationMap[franchiseName]) {
              locationMap[franchiseName] = {
                label: franchiseName,
                value: [{ location_id: locationId, location_name: locationName }]
              };
            } else {
              locationMap[franchiseName].value.push({
                location_id: locationId,
                location_name: locationName
              });
            }
          });

          const list = response.map((item) => ({
            label: item.locationName,
            value: item
          }));

          // setLocationList(list);

          const locationListForActiveHsppHistory = response.map((item) => ({
            label: item.locationName,
            value: item.locationId
          }));

          const franchiseListForActiveHsppHistory = Object.values(locationMap);
          // setFranchiseList(franchiseList);

          let userLocationMatch = [];
          let userLocationMatchForActiveHSppHistory = [];

          loggedInUser.location.forEach((elem) => {
            userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
            userLocationMatchForActiveHSppHistory.push(
              ...locationListForActiveHsppHistory.filter((item) => item.label == elem)
            );
            // userFranchiseMatchForActiveHSppHistory.push(
            //   ...franchiseListForActiveHsppHistory.forEach((franchise) =>
            //     franchise.filter((item) => item.value.location_name == elem)
            //   )
            // );
          });

          const userFranchiseMatchForActiveHSppHistory = franchiseListForActiveHsppHistory
            .map((company) => {
              const matchingLocations = company.value.filter((location) =>
                loggedInUser.location.includes(location.location_name)
              );
              return matchingLocations.length > 0
                ? { label: company.label, value: matchingLocations }
                : null;
            })
            .filter((company) => company !== null);

          if (loggedInUser.role !== 'Employee') {
            setLocationList(list);
            setFranchiseListForActiveHsppHistoryReport(franchiseListForActiveHsppHistory);
            setLocationListForActiveHsppHistoryReport(locationListForActiveHsppHistory);
            setDroppedPatientsDetails((prev) => ({
              ...prev,
              location: userLocationMatch
            }));
          } else {
            setLocationList(userLocationMatch);
            setFranchiseListForActiveHsppHistoryReport(userFranchiseMatchForActiveHSppHistory);
            setLocationListForActiveHsppHistoryReport(userLocationMatchForActiveHSppHistory);
            setDroppedPatientsDetails((prev) => ({
              ...prev,
              location: userLocationMatch
            }));
          }
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const handleGenerateDroppedPatientsReport = () => {
    navigate('/dropped-patients-report', {
      state: {
        droppedPatientsDetails: droppedPatientsDetails
      }
    });
  };
  const handleGenerateProtectionPlanBatteryReport = () => {
    navigate('/protection-plan-battery-report');
  };
  const handleGenerateInvalidBatteryReport = () => {
    navigate('/invalid-battery-report');
  };
  const handleFuturePlansReport = () => {
    navigate('/future-plans-report');
  };
  const handleGenerateActiveHsppPatientReport = () => {
    navigate('/active-hspp-patient-report', {
      state: {
        activeHsppPatientDetails: activeHsppPatientDetails
      }
    });
  };

  const handleGenerateActiveHsppPatientHistoryReport = () => {
    navigate('/active-hspp-patient-history-report', {
      state: {
        activeHsppPatientHistoryDetails: activeHsppPatientHistoryDetails
      }
    });
  };
  const handleGenerateAmplifonPatientsReport = () => {
    navigate('/amplifon-patient-report');
  };
  const handelGenerateChargebackReport = () => {
    navigate('/chargeback-report', {
      state: {
        chargebackDetails: chargebackDetails
      }
    });
  };
  const handleCardUpdateReport = () => {
    navigate('/card-update-report');
  };

  const handleNewsLetterReport = () => {
    if (newsLetterMonth.year && newsLetterMonth.year > 2000 && newsLetterMonth.year < 9999) {
      navigate('/newsletter-report', {
        state: {
          newsLetterMonth: newsLetterMonth
        }
      });
    } else toast.warning('No data available for this date !');
  };

  const handleLossAndDamageReport = () => {
    navigate('/loss-and-damage-report');
  };

  const handelGenerateHsppAttachmentReport = () => {
    navigate('/hspp-attatchment-report', {
      state: {
        hsppAttatchmentDetails: hsppAttatchmentDetails
      }
    });
  };
  return (
    <DashboardContainer>
      <Body
        _this={{
          droppedPatientsDetails,
          setDroppedPatientsDetails,
          locationList,
          handleGenerateDroppedPatientsReport,
          handleGenerateProtectionPlanBatteryReport,
          handleNewsLetterReport,
          setNewsLetterMonth,
          newsLetterMonth,
          activeHsppPatientDetails,
          setActiveHsppPatientDetails,
          activeHsppPatientOptions,
          handleGenerateActiveHsppPatientReport,
          handleGenerateAmplifonPatientsReport,
          handelGenerateChargebackReport,
          handleLossAndDamageReport,
          chargebackDetails,
          handleGenerateInvalidBatteryReport,
          setChargebackDetails,
          months,
          hsppAttatchmentDetails,
          setHsppAttatchmentDetails,
          handelGenerateHsppAttachmentReport,
          handleFuturePlansReport,
          handleCardUpdateReport,
          activeHsppPatientHistoryDetails,
          setActiveHsppPatientHistoryDetails,
          handleGenerateActiveHsppPatientHistoryReport,
          locationListForActiveHsppHistoryReport,
          franchiseListForActiveHsppHistoryReport
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
