import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input, DatePicker } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import { PermissionWrapper, HasPermission } from 'components';

const EditExpiryDateModal = ({ _this }) => {
  return (
    <Modal
      className="mt-10 mb-28"
      show={_this.editExpiryDateModal}
      onHide={() => _this.setEditExpiryDateModal(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Expiry Date</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditExpiryDateModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card>
          <div className="flex flex-col gap-4">
            <PermissionWrapper permission={'edit_l&r_warranty_date'}>
              <DatePicker
                label="L Warranty Expiry"
                placeholder="@due date"
                type="text"
                value={_this.editExpiryDateData?.l_warranty_expiry_date}
                onChange={(e) => {
                  _this.setEditExpiryDateData((prev) => ({
                    ...prev,
                    l_warranty_expiry_date: e
                  }));
                }}
              />
            </PermissionWrapper>
            <PermissionWrapper permission={'edit_l&r_warranty_date'}>
              <DatePicker
                label="R Warranty Expiry"
                placeholder="@due date"
                type="text"
                value={_this.editExpiryDateData?.r_warranty_expiry_date}
                onChange={(e) => {
                  _this.setEditExpiryDateData((prev) => ({
                    ...prev,
                    r_warranty_expiry_date: e
                  }));
                }}
              />
            </PermissionWrapper>
            <PermissionWrapper permission={'edit_expiry_date'}>
              <DatePicker
                label="Expiry Date"
                placeholder="@due date"
                type="text"
                value={_this.editExpiryDateData?.expiry_date}
                onChange={(e) => {
                  _this.setEditExpiryDateData((prev) => ({
                    ...prev,
                    expiry_date: e
                  }));
                }}
              />
            </PermissionWrapper>
          </div>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => _this.handleEditExpiryDateSubmit()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditExpiryDateModal;
