import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';

const TemporaryEditSubscription = ({ _this }) => {
  // console.log(_this.temporaryEditSubscriptionData);

  return (
    <Modal
      className="mt-28 mb-28 flex justify-center items-center h-[80%]"
      show={_this.temporaryEditSubscriptionModalOpen}
      onHide={() => {
        _this.setTemporaryEditSubscriptionModalOpen(false);
        // _this.setSubscriptionDetails(null);
      }}
      size="lg"
      // fullscreen="xl-down"
      // modal-dialog-scrollable
      aria-labelledby="contained-modal-title-vcenter"
      // dialogClassName="mt-28 mb-28"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
          Temporary Edit Subscription
        </Modal.Title>
        <div className="flex flex-col md:flex-row flex-wrap">
          <h3 className="text-md flex flex-wrap">
            Patient Name :
            <strong>
              {_this.customerDetails?.first_name + ' ' + _this.customerDetails.last_name}
            </strong>
          </h3>
          <h3 className="text-md flex flex-wrap">
            Patient ID :<strong>{_this.customerDetails?.customer_id}</strong>
          </h3>
        </div>

        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setTemporaryEditSubscriptionModalOpen(false);
            // _this.setSubscriptionDetails(null);
          }}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="flex">
          <h3 className="text-body-color text-xl">Product Information</h3>
          <Form className=" bg-slate-100 mt-2 p-2 rounded">
            <DatePicker
              label="L Warranty Expiry Date"
              value={_this.temporaryEditSubscriptionData?.l_warranty_expiry || ''}
              onChange={(e) => {
                _this.setTemporaryEditSubscriptionData((prev) => ({
                  ...prev,
                  l_warranty_expiry: e
                }));
              }}
            />
            <DatePicker
              label="R Warranty Expiry Date"
              value={_this.temporaryEditSubscriptionData?.r_warranty_expiry || ''}
              onChange={(e) => {
                _this.setTemporaryEditSubscriptionData((prev) => ({
                  ...prev,
                  r_warranty_expiry: e
                }));
              }}
            />

            <DatePicker
              label="Next Invoice Date"
              value={_this.temporaryEditSubscriptionData?.next_invoice_date || ''}
              onChange={(e) => {
                _this.setTemporaryEditSubscriptionData((prev) => ({
                  ...prev,
                  next_invoice_date: e
                }));
              }}
            />

            <DatePicker
              label="Expiry Date"
              value={_this.temporaryEditSubscriptionData?.expiry_date || ''}
              onChange={(e) => {
                _this.setTemporaryEditSubscriptionData((prev) => ({
                  ...prev,
                  expiry_date: e
                }));
              }}
            />

            <Dropdown
              label="Status"
              placeholder="@status"
              required
              type="text"
              options={[
                { label: 'OnHold', value: 'OnHold' },
                { label: 'Active', value: 'Active' },
                { label: 'Cancelled', value: 'Cancelled' },
                { label: 'Expired', value: 'Expired' }
              ]}
              aria-label="Default select example"
              value={_this.temporaryEditSubscriptionData?.status || ''}
              onChange={(e) => {
                _this.setTemporaryEditSubscriptionData((prev) => ({
                  ...prev,
                  status: e
                }));
              }}
            />
          </Form>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button color="primary" onClick={_this.handelTemporaryEditSubscriptionSubmit}>
          Update Subscription
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemporaryEditSubscription;

//test
