import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';

const EditSubscription = ({ _this }) => {
  // console.log(_this);

  return (
    <Modal
      className="mt-10"
      show={_this.editSubscriptionModalOpen}
      onHide={() => {
        _this.setEditSubscriptionModalOpen(false);
        // _this.setSubscriptionDetails(null);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="flex gap-2 items-center justify-between flex-wrap">
          <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
            Edit Subscription
          </Modal.Title>
          <div className="flex  flex-wrap gap-2">
            <h3 className="text-md">
              Patient Name :
              <strong>
                {_this.customerDetails?.first_name + ' ' + _this.customerDetails.last_name}
              </strong>
            </h3>
            <h3 className="text-md">
              Patient Id :<strong>{_this.customerDetails?.customer_id}</strong>
            </h3>
          </div>
        </div>

        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setEditSubscriptionModalOpen(false);
            // _this.setSubscriptionDetails(null);
          }}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="flex">
          <h3 className="text-body-color text-xl">Product Information</h3>
          <Form className=" bg-slate-100 mt-2 p-2 rounded">
            <Row>
              <Dropdown
                label="Chargable"
                placeholder="Select"
                required
                type="text"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]}
                value={_this?.selectedSubscriptionDataForEdit?.chargable}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('chargable', e);
                }}
              />
              <Dropdown
                label="Select Location"
                required
                type="text"
                options={_this.locationList}
                isClearable
                value={_this?.selectedSubscriptionDataForEdit?.location}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('location', e);
                }}
              />
            </Row>
            <Row>
              <Input
                label="Serial # Left"
                controlId="left_serial"
                placeholder=""
                type="text"
                value={_this?.selectedSubscriptionDataForEdit?.left_serial}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('left_serial', e.target.value);
                }}
              />
              <Input
                label="Serial # Right"
                controlId="right_serial"
                placeholder=""
                type="text"
                value={_this?.selectedSubscriptionDataForEdit?.right_serial}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('right_serial', e.target.value);
                }}
              />
            </Row>
            <Row>
              <Dropdown
                label="L Battery Size"
                placeholder="Select"
                required
                type="text"
                options={_this.batterySizeOptions}
                value={_this?.selectedSubscriptionDataForEdit?.l_battery_size}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('l_battery_size', e);
                }}
              />
              <Dropdown
                label="R Battery Size"
                placeholder="Select"
                required
                type="text"
                options={_this.batterySizeOptions}
                value={_this?.selectedSubscriptionDataForEdit?.r_battery_size}
                onChange={(e) => {
                  _this.updateSubscriptionInformation('r_battery_size', e);
                }}
              />
            </Row>
          </Form>

          <Checkbox
            className="mt-2 text-sm"
            label="Customer will receive monthly newsletter via email unless batteries are included."
            type="checkbox"
            id={'newsletter'}
            checked={_this?.selectedSubscriptionDataForEdit?.newsletter}
            onChange={(e) => {
              _this.updateSubscriptionInformation('newsletter', e.target.checked);
            }}
          />
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button color="primary" onClick={_this.handelEditSubscription}>
          Update Subscription
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSubscription;

//test
