import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, Row } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditPaymentDetails = ({ _this }) => {
  const [billingAddress, setBillingAddress] = useState(_this.customerDetails?.billing_address);

  const updateBillingAddress = (key, value) => {
    setBillingAddress((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  return (
    <Modal
      className="md:mt-10 flex justify-center items-center min-h-screen"
      show={_this.editPaymentModalOpen}
      onHide={() => _this.setEditPaymentModalOpen(false)}
      // fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Billing Details</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditPaymentModalOpen(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <h3 className="text-body-color text-xl">Billing Address</h3>
          <Form className="bg-white mt-2 p-2 rounded">
            <Input
              label="Address 1"
              placeholder=""
              type="text"
              required
              value={(billingAddress && billingAddress.address1) || ''}
              onChange={(e) => updateBillingAddress('address1', e.target.value)}
            />
            <Row>
              <Input
                label="Address 2"
                placeholder=""
                type="text"
                value={(billingAddress && billingAddress.address2) || ''}
                onChange={(e) => updateBillingAddress('address2', e.target.value)}
              />
              <Input
                label="City"
                placeholder=""
                type="text"
                required
                value={(billingAddress && billingAddress.city) || ''}
                onChange={(e) => updateBillingAddress('city', e.target.value)}
              />
            </Row>
            <Row>
              <Dropdown
                label="State"
                placeholder="Select"
                required
                type="text"
                options={_this.stateList}
                value={{ label: billingAddress?.state, value: billingAddress?.state }}
                onChange={(e) => updateBillingAddress('state', e.value)}
              />

              <Input
                label="Zipcode"
                placeholder=""
                required
                type="text"
                value={(billingAddress && billingAddress.zipcode) || ''}
                onChange={(e) => updateBillingAddress('zipcode', e.target.value)}
              />
            </Row>
            <Row>
              <Dropdown
                label="Country"
                placeholder="Select"
                required
                type="text"
                isDisabled
                defaultValue={billingAddress?.country}
                value={{ label: billingAddress?.country, value: billingAddress?.country }}
                onChange={(e) => updateBillingAddress('country', e)}
              />
            </Row>
          </Form>
        </Card>
        <Modal.Footer>
          <Button
            onClick={() => {
              _this.updatePaymentDetails(_this?.customerDetails?._id, billingAddress);
            }}
          >
            Update Details
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
export default EditPaymentDetails;
