import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input, DatePicker } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditDueDateModal = ({ _this }) => {
  return (
    <Modal
      show={_this.editDueDateModalVisibility}
      onHide={() => _this.setEditDueDateModalVisibility(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Due Date</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditDueDateModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <DatePicker
            label="Due Date"
            placeholder="@due date"
            type="text"
            required
            value={_this.editDueDateData?.due_date}
            onChange={(e) => {
              _this.setEditDueDateData((prev) => ({
                ...prev,
                due_date: e
              }));
            }}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => _this.handleEditDueDateSubmit()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditDueDateModal;
