import React from 'react';
import { Card, Input, Button } from 'components';
import { Form, Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditProfileModal = ({ _this }) => {
  return (
    <Modal
      className="mt-10"
      show={_this.editProfileModalOpen}
      onHide={() => {
        _this.setEditProfileModalOpen(false);
      }}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Profile</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditProfileModalOpen(false)} />
      </Modal.Header>

      <Modal.Body className="flex flex-col justify-center items-center">
        <Card className="mb-3 mt-2">
          <div className="text-md text-slate-500 mt-1">
            <Form className="bg-slate-100 mt-2 p-3 rounded">
              <Input
                label="First Name"
                placeholder="@first name"
                type="text"
                required
                value={_this.edit_profile.first_name}
                onChange={(e) =>
                  _this.setEdit_profile((prev) => ({
                    ...prev,
                    first_name: e.target.value
                  }))
                }
              />
              <Input
                label="Last Name"
                placeholder="@last name"
                type="text"
                required
                value={_this.edit_profile.last_name}
                onChange={(e) =>
                  _this.setEdit_profile((prev) => ({
                    ...prev,
                    last_name: e.target.value
                  }))
                }
              />
              <Input
                label="Email"
                placeholder="@email"
                type="text"
                required
                value={_this.edit_profile.mail}
                onChange={(e) =>
                  _this.setEdit_profile((prev) => ({
                    ...prev,
                    mail: e.target.value
                  }))
                }
              />
            </Form>
          </div>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => _this.UpdateProfile()}>Update Profile</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProfileModal;
