import React from 'react';
import { Card, Button, PermissionWrapper } from 'components';
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { currencyFormatter } from 'services/helpers';
import { IoMdRefresh } from 'react-icons/io';
import { GrAchievement } from 'react-icons/gr';
import { GiTrophy } from 'react-icons/gi';

const ContestInformation = ({ _this }) => {
  return (
    <Card className="flex mb-3">
      <div className="flex flex-col sm:flex-row justify-between border-b-[3px] pb-1 gap-3 mb-3">
        <h3 className="text-body-color text-xl md:text-2xl">Contest Information</h3>
        <h6 className="text-body-color text-lg font-semibold">
          Contest:{' '}
          <span className="text-slate-900">{_this.liveContestResult[0]?.contest_name}</span>
        </h6>
      </div>

      {_this.liveContestResult.length > 0 &&
        _this.liveContestResult.map((item, i) => {
          // item.crossed_hurdle = 40;
          return (
            <div key={i} className="">
              <h6 className="text-body-color text-lg font-semibold pb-1 mt-2">
                {item.location_group}
              </h6>

              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
                <div className="bg-body-backgroudColor rounded-lg w-36">
                  <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
                    Net Gain
                  </h6>
                  {item ? (
                    <div className="mt-1 flex flex-col justify-center items-center gap-1 h-16">
                      <p
                        className={`text-5xl ${
                          item.patients_added < 0 ? 'text-red-700' : 'text-green-700'
                        } font-semibold tracking-wide`}
                      >
                        {item.patients_added}
                      </p>
                    </div>
                  ) : (
                    <IoMdRefresh
                      size={50}
                      className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                    />
                  )}
                </div>

                <div className="bg-body-backgroudColor rounded-lg w-36">
                  <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
                    Current Active Patients
                  </h6>
                  {item ? (
                    <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
                      <p className={`text-3xl text-yellow-700 font-semibold tracking-wide`}>
                        {item.current_active_patients}
                      </p>
                    </div>
                  ) : (
                    <IoMdRefresh
                      size={50}
                      className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                    />
                  )}
                </div>

                <div className="bg-body-backgroudColor rounded-lg w-36">
                  <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
                    Percent HSPP
                  </h6>
                  {item ? (
                    <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
                      <p className={`text-4xl text-yellow-700 font-semibold tracking-wide`}>
                        {item.percHspp}
                      </p>
                    </div>
                  ) : (
                    <IoMdRefresh
                      size={50}
                      className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                    />
                  )}
                </div>

                <div className="bg-body-backgroudColor rounded-lg w-36">
                  <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
                    Need To Achieve
                  </h6>
                  {item ? (
                    <div className="mt-1 flex flex-col justify-center items-center gap-3 h-20">
                      <p className={`text-4xl text-yellow-700 font-semibold tracking-wide`}>
                        {item.ongoing_hurdle_value - item.patients_added}
                      </p>
                      <p className="text-xs tracking-wider font-semibold text-green-600 px-1">
                        In Progress: {item.ongoing_hurdle}
                      </p>
                    </div>
                  ) : (
                    <IoMdRefresh
                      size={50}
                      className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                    />
                  )}
                </div>

                <div className="bg-body-backgroudColor rounded-lg w-36">
                  <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
                    Achievement
                  </h6>
                  {item.crossed_hurdle ? (
                    <div className="mt-1 flex flex-col justify-center items-center gap-1 h-20">
                      <p
                        className={`text-sm text-green-600 font-semibold tracking-wide text-center`}
                      >
                        Congratulation!
                      </p>
                      <GiTrophy
                        className="animate-bounce text-green-600"
                        size={30}
                        // fill="green"
                      />
                      <p className={`text-xs text-yellow-700 font-semibold tracking-wide`}>
                        Achieved {item?.crossed_hurdle} members
                      </p>
                    </div>
                  ) : (
                    <div className="mt-1 flex flex-col justify-center items-center gap-3 h-20">
                      <GiTrophy
                        className="animate-pulse text-red-600 rotate-180"
                        size={30}
                        rotate={180}
                        // fill="green"
                      />
                      <p className={`text-xs text-red-600 font-semibold tracking-wide`}>
                        No Achievement Found
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </Card>
  );
};

export default ContestInformation;
