import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const DeleteEmployeeModal = ({ _this }) => {
  return (
    <Modal
      show={_this.deleteEmployeeModalVisibility}
      onHide={() => _this.setDeleteEmployeeModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete Employee</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setDeleteEmployeeModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2 justify-center items-center">
          <h3 className="text-xl text-gray-600">
            Are you sure you want to delete{' '}
            <span className="text-black">{_this.deleteEmployeeData.name}</span>
          </h3>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => _this.deleteEmployeeSubmit()}
          className="bg-rose-700 hover:bg-rose-800 text-white text-xl rounded flex flex-row self-center w-full h-10 justify-center items-center"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteEmployeeModal;
