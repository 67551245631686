import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';

const AddNewPlan = ({ _this }) => {
  const subscription = _this.subscriptionDetails;
  const runCallback = (cb) => {
    return cb();
  };

  return (
    <Modal
      className="md:mt-10 flex justify-center items-center min-h-screen"
      show={_this.addNewPlanModalOpen}
      onHide={() => {
        _this.setAddNewPlanModalOpen(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add New Plan</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setAddNewPlanModalOpen(false);
          }}
        />
      </Modal.Header>

      <Modal.Body className="flex flex-col justify-center items-center">
        {runCallback(() => {
          const row = [];
          for (let i = 0; i < _this.noOfPlans.value; i++) {
            row.push(
              <div key={i}>
                <Card className="mb-3 flex">
                  <h3 className="text-body-color text-xl">Plan Details</h3>
                  <Form className="bg-slate-100 mt-2 p-2 rounded">
                    <Row>
                      <DatePicker
                        required
                        label="L Warranty Expiry Date"
                        value={
                          _this.productInformation[i]?.l_warranty_expiry
                            ? _this.productInformation[i]?.l_warranty_expiry
                            : ''
                        }
                        onChange={(e) => {
                          _this.updateProductInformation('l_warranty_expiry', e || '', i);
                        }}
                        onCalendarOpen={() => {
                          if (!_this.productInformation[i]?.l_warranty_expiry)
                            _this.updateProductInformation(
                              'l_warranty_expiry',
                              moment().add(3, 'years').toDate(),
                              i
                            );
                        }}
                      />
                      <div>
                        <DatePicker
                          label="R Warranty Expiry Date"
                          value={
                            _this.productInformation[i]?.r_warranty_expiry
                              ? _this.productInformation[i].r_warranty_expiry
                              : ''
                          }
                          onChange={(e) => {
                            _this.updateProductInformation('r_warranty_expiry', e || '', i);
                          }}
                          onCalendarOpen={() => {
                            if (!_this.productInformation[i]?.r_warranty_expiry)
                              _this.updateProductInformation(
                                'r_warranty_expiry',
                                moment().add(3, 'years').toDate(),
                                i
                              );
                          }}
                        />
                        <Checkbox
                          label="Set as Blank"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              _this.updateProductInformation('r_warranty_expiry', '', i);
                            } else {
                              _this.updateProductInformation(
                                'r_warranty_expiry',
                                _this.productInformation[i]?.l_warranty_expiry,
                                i
                              );
                            }
                          }}
                          controlId="expiry"
                        />
                      </div>
                    </Row>
                    <Dropdown
                      label="Select Plan"
                      required
                      type="text"
                      options={_this.filteredPlanList(i)}
                      isClearable
                      value={_this.productInformation[i]?.plan}
                      onChange={(e) => {
                        _this.updateProductInformation('plan', e, i);
                      }}
                    />
                    <Row>
                      <Dropdown
                        label="Plan Interval"
                        placeholder="Select"
                        required
                        type="text"
                        options={[
                          { label: 'Monthly', value: 'monthly' },
                          { label: 'Annually', value: 'annually' },
                          { label: 'Free', value: 'free' }
                        ]}
                        value={_this.productInformation[i]?.plan_interval}
                        onChange={(e) => _this.updateProductInformation('plan_interval', e, i)}
                      />
                      <Input
                        label={
                          _this.productInformation[i]?.plan_interval?.value === 'monthly'
                            ? 'Months'
                            : _this.productInformation[i]?.plan_interval?.value === 'annually'
                            ? 'Years'
                            : 'Months/Years'
                        }
                        placeholder=""
                        type="text"
                        value={_this.productInformation[i]?.plan_frequency || ''}
                        disabled
                      />
                    </Row>
                  </Form>
                </Card>
              </div>
            );
          }
          return row;
        })}
      </Modal.Body>

      <Modal.Footer>
        <Button color="primary" onClick={() => _this.finalDataSubmitforPlan()}>
          Add Plan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewPlan;

//test
