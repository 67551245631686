import React from 'react';
import { Card, Button, PermissionWrapper, LineGraph, PieChart } from 'components';
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { currencyFormatter } from 'services/helpers';
import { IoMdRefresh } from 'react-icons/io';

const RevenueInformation = ({ _this }) => {
  const navigate = useNavigate();
  const revenue = _this.revenueData;
  const YesterdayAndTodayRevenue = {
    labels: [
      [
        'Yesterday',
        `${currencyFormatter.format(revenue?.yesterdayRevenue?.amount.toFixed(2))} (${
          revenue?.yesterdayRevenue?.count
        })`
      ],
      [
        'Today',
        `${currencyFormatter.format(revenue?.todayRevenue?.amount.toFixed(2))} (${
          revenue?.todayRevenue?.count
        })`
      ]
    ],
    datasets: [
      {
        label: 'Revenues',
        data: [revenue?.yesterdayRevenue?.amount, revenue?.todayRevenue?.amount],
        borderColor: '#87bc45'
      }
    ]
  };

  const LastMonthAndThisMonthRevenue = {
    labels: [
      [
        'Last Month',
        `${currencyFormatter.format(revenue?.lastMonthRevenue?.amount.toFixed(2))} (${
          revenue?.lastMonthRevenue?.count
        })`
      ],
      [
        'This Month',
        `${currencyFormatter.format(revenue?.thisMonthRevenue?.amount.toFixed(2))} (${
          revenue?.thisMonthRevenue?.count
        })`
      ]
    ],
    datasets: [
      {
        label: 'Revenues',
        data: [revenue?.lastMonthRevenue?.amount, revenue?.thisMonthRevenue?.amount],
        borderColor: '#b3d4ff'
      }
    ]
  };

  const LastYearAndThisYearRevenue = {
    labels: [
      [
        'Last Year',
        `${currencyFormatter.format(Number(revenue?.lastYearRevenue?.amount).toFixed(2))} (${
          revenue?.lastYearRevenue?.count
        })`
      ],
      [
        'This Year',
        `${currencyFormatter.format(Number(revenue?.thisYearRevenue?.amount).toFixed(2))} (${
          revenue?.thisYearRevenue?.count
        })`
      ]
    ],
    datasets: [
      {
        label: 'Revenues',
        data: [Number(revenue?.lastYearRevenue?.amount), Number(revenue?.thisYearRevenue?.amount)],
        borderColor: '#e60049'
      }
    ]
  };

  const DeclinedPayments = {
    labels: [
      [
        `Declined Payments
        ${currencyFormatter.format(Number(revenue?.declinedPayments?.amount).toFixed(2))} (${
          revenue?.declinedPayments?.count
        })`
      ],
      [
        `This Month
        ${currencyFormatter.format(
          Number(revenue?.thisMonthDeclinedPayments?.amount).toFixed(2)
        )} (${revenue?.thisMonthDeclinedPayments?.count})`
      ]
    ],
    datasets: [
      {
        label: 'Revenues',
        data: [
          Number(revenue?.declinedPayments?.amount),
          Number(revenue?.thisMonthDeclinedPayments?.amount)
        ],
        backgroundColor: ['#e60049', '#9b19f5']
      }
    ]
  };
  return (
    <div className="w-full flex flex-col justify-between gap-4 mb-5 mr-2">
      <div className="w-full bg-white rounded-xl">
        {/* Heading*/}
        <div className="flex flex-col items-center rounded-xl gap-2 theme-light-blue p-3 rounded-t-xl">
          <h1>REVENUE INFORMATION</h1>
          <PermissionWrapper permission={'select_revenue_information_location'}>
            <div className="flex flex-col gap-2 items-center justify-center">
              <Form.Select
                className="md:w-64 rounded-md bg-transparent border-theme-dashboard-dark-blue text-theme-dashboard-dark-blue focus:border-button-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  _this.getSubCategoriesFnachiseLocationOptions &&
                    _this.getSubCategoriesFnachiseLocationOptionsForRevenue(
                      _this.keysOfFranchiseLocationData[e.target.value]
                    );
                  _this.setSelectedFieldsForRevenueInformation((prev) => ({
                    ...prev,
                    key: e.target.value
                  }));
                }}
              >
                <option>Select Parameters</option>
                {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                className="md:w-64 rounded-md bg-transparent border-theme-dashboard-dark-blue text-theme-dashboard-dark-blue focus:border-button-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  _this.onFranchiseLocationOptionsForRevenueSubmit(e.target.value);
                  _this.setSelectedFieldsForRevenueInformation((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              >
                <option>Select Options</option>
                <option>Total</option>
                {_this.subCategoriesFranchiseLocationOptionsForRevenue?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
          </PermissionWrapper>
        </div>
        <PermissionWrapper permission={'net_earned_revenue'}>
          <Card
            bordered={false}
            className="flex-1 rounded-xl bg-theme-dull-white"
            title="OFFLINE WORK"
            // extra={<BsThreeDots className="text-theme-text-color" />}
          >
            <div className="flex flex-col gap-2">
              <p className="">{"Yesterday & Today's Revenue"}</p>
              <div className="flex flex-col gap-4 h-40">
                {_this?.revenueData?.yesterdayRevenue ? (
                  <LineGraph
                    data={YesterdayAndTodayRevenue}
                    onClick={(evt, element) => {
                      if (element[0].index == 0) {
                        navigate('/dashboard-invoices', {
                          state: _this.revenueData?.yesterdayRevenue
                        });
                      } else {
                        navigate('/dashboard-invoices', { state: _this.revenueData?.todayRevenue });
                      }
                    }}
                  />
                ) : (
                  <IoMdRefresh
                    size={100}
                    className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                  />
                )}
              </div>
            </div>
          </Card>
        </PermissionWrapper>
      </div>

      <PermissionWrapper permission={'net_earned_revenue'}>
        <Card
          bordered={false}
          className="flex-1 rounded-xl bg-theme-dull-white"
          title="OFFLINE WORK"
          // extra={<BsThreeDots className="text-theme-text-color" />}
        >
          <div className="flex flex-col gap-2">
            <p className="">{"Last Month & This Month's Revenue"}</p>
            <div className="flex flex-col gap-4 h-40">
              {_this?.revenueData?.lastMonthRevenue ? (
                <LineGraph
                  data={LastMonthAndThisMonthRevenue}
                  onClick={(evt, element) => {
                    if (element[0].index == 0) {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.lastMonthRevenue
                      });
                    } else {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.thisMonthRevenue
                      });
                    }
                  }}
                />
              ) : (
                <IoMdRefresh
                  size={100}
                  className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                />
              )}
            </div>
          </div>
        </Card>
      </PermissionWrapper>

      <PermissionWrapper permission={'net_earned_revenue'}>
        <Card
          bordered={false}
          className="flex-1 rounded-xl bg-theme-dull-white"
          title="OFFLINE WORK"
          // extra={<BsThreeDots className="text-theme-text-color" />}
        >
          <div className="flex flex-col gap-2">
            <p className="">{"Last Year & This Year's Revenue"}</p>
            <div className="flex flex-col gap-4 h-40">
              {_this?.revenueData?.lastYearRevenue ? (
                <LineGraph
                  data={LastYearAndThisYearRevenue}
                  onClick={(evt, element) => {
                    if (element[0].index == 0) {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.lastYearRevenue
                      });
                    } else {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.thisYearRevenue
                      });
                    }
                  }}
                />
              ) : (
                <IoMdRefresh
                  size={100}
                  className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                />
              )}
            </div>
          </div>
        </Card>
      </PermissionWrapper>

      <PermissionWrapper permission={'failed_payments'}>
        <Card
          bordered={false}
          className="flex-1 rounded-xl bg-theme-dull-white"
          title="OFFLINE WORK"
          // extra={<BsThreeDots className="text-theme-text-color" />}
        >
          <div className="flex flex-col gap-2">
            <p className="">Failed Payments</p>
            <div className="flex flex-col gap-4 h-72">
              {_this?.revenueData?.declinedPayments ? (
                <PieChart
                  data={DeclinedPayments}
                  onClick={(evt, element) => {
                    if (element[0].index == 0) {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.declinedPayments
                      });
                    } else {
                      navigate('/dashboard-invoices', {
                        state: _this.revenueData?.thisMonthDeclinedPayments
                      });
                    }
                  }}
                />
              ) : (
                <IoMdRefresh
                  size={100}
                  className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
                />
              )}
            </div>
          </div>
        </Card>
      </PermissionWrapper>
    </div>

    // <Card className="flex mb-3">
    //   <div className="flex flex-col sm:flex-row justify-between border-b-[3px] pb-1 gap-3">
    //     <h3 className="text-body-color text-xl md:text-2xl">Revenue Information</h3>
    //     <PermissionWrapper permission={'select_revenue_information_location'}>
    //       <div className="flex flex-col sm:flex-row gap-2 items-center justify-center mb-3">
    //         <Form.Select
    //           className="md:w-48 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.getSubCategoriesFnachiseLocationOptions &&
    //               _this.getSubCategoriesFnachiseLocationOptionsForRevenue(
    //                 _this.keysOfFranchiseLocationData[e.target.value]
    //               );
    //             _this.setSelectedFieldsForRevenueInformation((prev) => ({
    //               ...prev,
    //               key: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Parameters</option>
    //           {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>

    //         <Form.Select
    //           className="w-58 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.onFranchiseLocationOptionsForRevenueSubmit(e.target.value);
    //             _this.setSelectedFieldsForRevenueInformation((prev) => ({
    //               ...prev,
    //               value: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Options</option>
    //           <option>Total</option>
    //           {_this.subCategoriesFranchiseLocationOptionsForRevenue?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>
    //       </div>
    //     </PermissionWrapper>
    //   </div>

    //   <PermissionWrapper permission={'net_earned_revenue'}>
    //     <div className="flex justify-between">
    //       <h6 className="text-body-color text-lg font-semibold pb-1 mt-2">Net Earned Revenue</h6>
    //       <h6 className="text-body-color mt-2 pb-1 text-lg">
    //         As per{' '}
    //         <span className="text-black">
    //           {_this.selectedFieldsforRevenueInformation.value != '' &&
    //           _this.selectedFieldsforRevenueInformation.key != 'Total'
    //             ? `${_this.selectedFieldsforRevenueInformation.key}: ${_this.selectedFieldsforRevenueInformation.value}`
    //             : 'Total'}
    //         </span>
    //       </h6>
    //     </div>
    //     <div className="flex justify-around items-center mt-2 flex-wrap gap-y-5">
    //       <div className="flex gap-4">
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.yesterdayRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               Yesterday
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.yesterdayRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.yesterdayRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.yesterdayRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.todayRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               Today
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.todayRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.todayRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.todayRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //       </div>

    //       <div className="flex gap-4">
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.lastMonthRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               Last Month
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.lastMonthRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.lastMonthRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.lastMonthRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.thisMonthRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               This Month
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.thisMonthRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.thisMonthRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.thisMonthRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //       </div>

    //       <div className="flex gap-4">
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.lastYearRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               Last Year
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.lastYearRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.lastYearRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.lastYearRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.thisYearRevenue}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               This Year
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.lastYearRevenue ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.thisYearRevenue?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-green-600 px-3">
    //                     Paid Invoices: {_this.revenueData?.thisYearRevenue?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   </PermissionWrapper>
    //   <PermissionWrapper permission={'failed_payments'}>
    //     <h6 className="text-body-color font-semibold pb-1 mt-4">Failed Payments</h6>
    //     <div className="flex flex-col md:flex-row gap-2">
    //       <div className="w-full">
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.declinedPayments}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1 mt-2">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               Payments Declined
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.declinedPayments ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(_this.revenueData?.declinedPayments?.amount)}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-rose-600 px-4">
    //                     Declined Invoices: {_this.revenueData?.declinedPayments?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //       <div className="w-full">
    //         <Link to="/dashboard-invoices" state={_this.revenueData?.thisMonthDeclinedPayments}>
    //           <div className="bg-body-backgroudColor rounded-lg w-auto py-1 mt-2">
    //             <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //               This Month Declined Payments
    //             </h6>
    //             <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //               {_this?.revenueData?.thisMonthDeclinedPayments ? (
    //                 <div className="text-center">
    //                   <p className="text-2xl text-yellow-700 font-semibold tracking-wide mt-2 px-2">
    //                     {currencyFormatter.format(
    //                       _this.revenueData?.thisMonthDeclinedPayments?.amount
    //                     )}
    //                   </p>
    //                   <p className="text-sm tracking-wider font-semibold text-rose-600 px-4">
    //                     Declined Invoices: {_this.revenueData?.thisMonthDeclinedPayments?.count}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <IoMdRefresh
    //                   size={50}
    //                   className="animate-spin text-yellow-700 font-semibold tracking-wide mt-2 mx-5 px-2"
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   </PermissionWrapper>
    // </Card>
  );
};

export default RevenueInformation;
