import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';
import Config from 'config';

const seeLogEmailDataModal = ({ _this }) => {
  const data = _this?.logEmailData && JSON.parse(_this?.logEmailData?.data);

  return (
    <Modal
      show={_this.seeLogEmailDataModalOpen}
      onHide={() => _this.setSeeLogEmailDataModalOpen(false)}
      size="xl"
      fullscreen="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-16 h-[90%]"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Email Log</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setSeeLogEmailDataModalOpen(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex flex-col gap-4 mr-2">
          <div className="flex justify-between">
            <h3 className="text-font-label text-lg">From: {data?.from}</h3>
            <h3 className="text-font-label text-lg">To: {data?.to}</h3>
          </div>
          <h3 className="text-font-label text-lg">
            Date: {moment(_this.logEmailData?.createdAt).format('YYYY-MM-DD')}
          </h3>
          <h3 className="text-font-label text-lg">Subject: {data?.subject}</h3>
          <iframe
            src={`${Config.API_URL}v1/public/view-email-template/${_this.logEmailData?._id}`}
            className="w-[100%] h-96"
          ></iframe>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => _this.setSeeLogEmailDataModalOpen(false)}>Go Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default seeLogEmailDataModal;
