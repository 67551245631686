import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input, Dropdown } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditRoleModal = ({ _this }) => {
  const [note, setNote] = useState('');

  return (
    <Modal
      show={_this.editRoleModalVisibility}
      onHide={() => _this.setEditRoleModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Role</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditRoleModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <Input
            label="Role Name"
            placeholder="@Enter Role Name"
            type="text"
            required
            value={_this.editRoleData?.role_name || ''}
            onChange={(e) => _this.updateEditRoleData('role_name', e.target.value)}
          />

          <Dropdown
            label="Permissions"
            required
            type="text"
            options={_this.permissionList}
            // isClearable
            aria-label="Default select example"
            onChange={(e) => {
              _this.updateEditRoleData('permissions', e);
            }}
            value={_this.editRoleData?.permissions}
            isMulti
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.submitEditRoleData();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditRoleModal;
