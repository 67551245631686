import React from 'react';
import { Input, Button, Checkbox } from 'components/Form';
import { Link } from 'react-router-dom';

import ButtonBTN from 'react-bootstrap/Button';
import ForgetPasswordModal from './ForgetPasswordModal';
import ChangePasswordModal from './ChangePasswordModal';

const Section = ({ _this }) => {
  return (
    <div className="min-w-screen min-h-screen">
      <div className="h-[60rem] sm:h-screen bg-login-bg bg-no-repeat bg-center bg-cover flex flex-col justify-center items-center gap-6 py-5">
        <div className="w-96 sm:w-[30rem] backdrop-blur-xs bg-white/30 p-2 rounded-2xl flex flex-col items-center gap-4">
          <img src="/logo.png" className="object-contain w-96" alt="hspp-logo" />
        </div>
        <div className="tracking-wide text-center text-white flex flex-col gap-4">
          <h1 className="font-bold text-3xl">Welcome to HSPP</h1>
          <div className="flex flex-col">
            <h1 className="font-bold text-xl">Welcome Back</h1>
            <p className="text-white">Login to your Account</p>
          </div>
        </div>

        <div className="mb-2">
          <label className="block text-base mb-2 text-white">Your Email</label>
          <input
            className="py-2 text-xl outline-none bg-inherit autofill:bg-inherit w-96 sm:w-[30rem] border-white text-white rounded-full"
            id="email"
            placeholder="Enter Email"
            type="email"
            value={_this.email}
            onChange={(e) => _this.setEmail(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label className="block text-base mb-2 text-white">Your Password</label>
          <input
            className="py-2 text-xl outline-none bg-inherit focus:bg-inherit active:bg-inherit w-96 sm:w-[30rem] border-white text-white rounded-full"
            id="password"
            placeholder="Enter password"
            type="password"
            value={_this.password}
            onChange={(e) => _this.setPassword(e.target.value)}
          />
        </div>
        <div className="w-96 sm:w-[30rem] flex justify-between z-40">
          <Checkbox
            value={_this.rememberMe}
            label="Remember Me"
            type="checkbox"
            controlId="formBasicCheckbox"
            className="outline-none bg-inherit border-white text-white rounded-full"
            onChange={(e) => _this.setRememberMe(!_this.rememberMe)}
          />

          <div className="flex flex-col gap-2 rounded-full">
            <button
              onClick={() => _this.onLogin()}
              // style="bg-inherit hover:bg-transparent"
              // color="warning"
              className="bg-button-primary hover:bg-button-primaryHover text-white border-none rounded-full w-40 px-4 py-2"
              // disabled={!(_this.email && _this.password)}
            >
              Login
            </button>
            <div className="flex justify-end text-white px-2">
              <div
                onClick={() => _this.setForgetPasswordModalVisibility(true)}
                className="font-normal text-base text-right cursor-pointer hover:text-slate-300"
              >
                Forgot Password
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          src="/miracle_ear_hearing_aid.png"
          className="h-48 w-96 absolute bottom-[67%] md:bottom-[57%] sm:left-36 overflow-hidden"
          alt="hspp-logo"
        />

        <div className="min-w-screen h-4 bg-[#0b5382]"></div>

        <div className="flex flex-col justify-center items-center min-w-screen bg-[#1270bc] p-2">
          <h3 className="tracking-wide text-center text-[#ffe11a] font-bold text-xl">
            Benefits Include
          </h3>
          <h2 className="tracking-wide text-center text-white text-lg">
            Free Hearing Aid replacements | Free Hearing Aid repairs
          </h2>
          <h2 className="tracking-wide text-center text-white text-lg">
            Monthly batteries delivered right to your door | Exclusive discounts available through
            BenefitHub
          </h2>
        </div>
      </div>
      <ForgetPasswordModal _this={_this} />
      <ChangePasswordModal _this={_this} />
    </div>
  );
};

export default Section;
