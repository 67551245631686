import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const RefundModal = ({ _this }) => {
  return (
    <Modal
      show={_this.refundModalVisibility}
      // onHide={() => _this.setRefundModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Initiate Refund</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setRefundModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-col">
        <div className="flex justify-between">
          <h3 className="font-semibold text-sm">
            Name: {_this.customerDetails?.first_name} {_this.customerDetails?.last_name}
          </h3>

          <h3 className="font-semibold text-sm">
            Patient ID: {_this.customerDetails?.customer_id}
          </h3>
        </div>
        <Card className="mb-3 flex mr-2">
          <Input
            label="Amount"
            placeholder="@Enter"
            type="text"
            required
            value={_this.refundDetails?.amount}
            onChange={(e) =>
              _this.setRefundDetails((prev) => ({
                ...prev,
                amount: e.target.value
              }))
            }
          />
          <Input
            label="Reason"
            placeholder="@Enter"
            type="text"
            required
            value={_this.refundDetails?.reason}
            onChange={(e) =>
              _this.setRefundDetails((prev) => ({
                ...prev,
                reason: e.target.value
              }))
            }
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.initiateRefund(_this.refundDetails);
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefundModal;
