import React, { forwardRef } from 'react';
import { Card, Button, Input } from 'components';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';

const Section = ({ _this }) => {
  return (
    <>
      <Modal
        centered
        show={_this.deletePlanModalOpen}
        onHide={() => _this.setDeletePlanModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Delete Plan Details</Modal.Title>
          <AiFillCloseCircle
            onClick={() => _this.setDeletePlanModalOpen(false)}
            className="ml-2 text-red-400 hover:text-red-500"
            size={30}
          />
        </Modal.Header>
        <Modal.Body>Are you sure to delete this plan?</Modal.Body>
        <Modal.Footer>
          <div className="flex flex-row h-12">
            <Button style="w-24" onClick={() => _this.setDeletePlanModalOpen(false)}>
              Close
            </Button>
            <Button
              style="bg-red-500 hover:bg-red-600 w-40 ml-5"
              onClick={() => {
                _this.deletePlan();
              }}
            >
              Yes Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Section;
