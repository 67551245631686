import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Card, Button, Input, Dropdown, Row, AutSuggestInput } from 'components';

const RequestFormModal = ({ _this }) => {
  return (
    <Modal
      show={_this?.openRequestFormModal}
      centered={false}
      onHide={() => _this?.setOpenRequestFormModal(false)}
      size="lg"
      className="my-5 h-[90%]"
    >
      <Modal.Header>
        <Modal.Title>L&D Approvals Request Form</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setOpenRequestFormModal(false),
              _this.setCustomerDetails([]),
              _this.setSerialNumberOptions([]);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <Form className="bg-slate-100 flex flex-col items-center justify-center gap-2 p-2 rounded">
            <AutSuggestInput
              onSearch={_this.loadCustomerOptions}
              onSelect={_this.updateCustomerDetailsBySelection}
              label={'Patient ID'}
              controlId={null}
              isLoading={_this.isPatientIdLoading}
              items={_this.autoSuggestList}
              required
              placeholder={_this.customerDetails?.customer_id || '@Please enter the ID'}
            />
            <Row className="flex flex-col sm:flex-row">
              <Input
                //   className="w-48"
                label="Patient Name"
                // placeholder="@Patient Name"
                type="text"
                value={
                  _this.customerDetails.first_name
                    ? `${_this.customerDetails.first_name} ${_this.customerDetails.last_name}`
                    : ''
                }
                required
                disabled
              />
              <Input
                //   className="w-48"
                label="City"
                // placeholder="@Location"
                type="text"
                value={_this.customerDetails.city}
                disabled
              />
              <Dropdown
                // className="w-80"
                label="Location"
                placeholder="@location"
                isClearable
                required
                type="text"
                options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
                value={_this.customerDetails?.location}
                onChange={(e) => {
                  _this.updateCustomerDetails('location', e);
                }}
              />
            </Row>
            <Row className="flex flex-col sm:flex-row justify-center items-end">
              <div className="flex gap-1">
                <IoIosAddCircleOutline
                  size={30}
                  className="mt-4 cursor-pointer hover:text-button-primaryHover font-bold"
                  onClick={() => {
                    _this.setAddSerialNumberModalVisibility(true);
                    //   _this.setOpenRequestFormModal(false);
                  }}
                />
                <Dropdown
                  className="w-80"
                  label="Select Serial Number"
                  placeholder="Select"
                  isClearable
                  isMulti
                  required
                  type="text"
                  options={_this.serialNumberOptions}
                  value={_this.customerDetails?.serialNumber}
                  onChange={(e) => {
                    _this.onSelectingSerialNumber(e);
                  }}
                />
              </div>

              <Dropdown
                className="w-80"
                label="Select Type"
                placeholder="Select"
                required
                type="text"
                options={[
                  {
                    label: 'Loss & Damage',
                    value: 'loss-&-damage'
                  },
                  {
                    label: 'Repair',
                    value: 'repair'
                  }
                ]}
                value={_this.customerDetails.type}
                onChange={(e) => _this.updateCustomerDetails('type', e)}
              />
            </Row>
          </Form>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            _this.customerDetails?.customer_id &&
            _this.customerDetails?.serialNumber != null &&
            _this.customerDetails?.type &&
            _this.customerDetails?.location
              ? false
              : true
          }
          onClick={_this.submitRequestApprovalForm}
        >
          Request Approval
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestFormModal;
