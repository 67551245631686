import React from 'react';
import { Card, Button, SuperAdminProfile } from 'components';
import CustomerInformation from './CustomerInformation';
import RevenueInformation from './RevenueInformation';
import { PermissionWrapper, HasPermission } from 'components';
import Alert from 'react-bootstrap/Alert';
import ContestInformation from './ContestInformation';
import GoodDayHero from './GoodDayHero';
import MyProfile from './MyProfile';

const Section = ({ _this }) => {
  return (
    <>
      {_this.user?.is_password_changed == false ? (
        <SuperAdminProfile
          admin={_this.user}
          is_password_changed={_this.user?.is_password_changed}
        />
      ) : (
        <>
          {/* <Alert key={'info'} variant={'info'}>
            <strong>Appologies for the inconvenience.</strong> We are working on making the
            Dashboard faster. However, customer list and customer creation functionalities are
            working as expected.
          </Alert> */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="w-full sm:w-[70%]">
                <GoodDayHero _this={_this} />
              </div>
              <div className="w-full sm:w-[30%]">
                <MyProfile _this={_this} />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <div className="w-full sm:w-[70%] flex flex-col gap-4">
                <PermissionWrapper permission={'view_customer_information'}>
                  <CustomerInformation _this={_this} />
                </PermissionWrapper>

                <PermissionWrapper permission={'view_contest_information'}>
                  <ContestInformation _this={_this} />
                </PermissionWrapper>
              </div>
              <div className="w-full sm:w-[30%]">
                <PermissionWrapper permission={'view_revenue_information'}>
                  <RevenueInformation _this={_this} />
                </PermissionWrapper>
              </div>
            </div>
          </div>

          {/* <Card className="mb-3 flex basis-1/4 mt-2"></Card>

          {/* <div className="w-full sm:w-[30%]">
              <div className="flex flex-col gap-4">
                <MyProfile _this={_this} />
                <PermissionWrapper permission={'view_revenue_information'}>
                  <RevenueInformation _this={_this} />
                </PermissionWrapper>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default Section;
