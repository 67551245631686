import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const AddNote = ({ _this }) => {
  const [note, setNote] = useState('');

  return (
    <Modal
      show={_this.addNoteModalOpen}
      onHide={() => _this.setAddNoteModalOpen(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Note</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setAddNoteModalOpen(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <Input
            label="Note"
            placeholder="Type...."
            type="text"
            required
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.addNote(note);
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNote;
