import React, { useEffect, useState } from 'react';
import { Input, Button, Checkbox, Row } from 'components';
import { Form, Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Card } from 'components';
import Cards from 'react-credit-cards-2';

const Body = ({ _this }) => {
  const [cardExpiry, setCardExpiry] = useState('');

  useEffect(() => {
    let expiry = cardExpiry.toString();
    if (cardExpiry.includes('/') == false) {
      if (expiry.length == 2) expiry = expiry + '/';
    }
    _this.updateCardDetails('expiry', expiry);
  }, [cardExpiry]);
  return (
    <Modal
      className="flex justify-center items-center min-h-screen md:mt-10"
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="flex gap-2 items-center justify-between flex-wrap">
          <Modal.Title id="contained-modal-title-vcenter">Add Payment Method</Modal.Title>
          <div className="flex flex-wrap gap-5 mx-5">
            <h3 className="text-md">
              Patient Name :
              <strong>
                {' ' + _this?.customer?.first_name + ' ' + _this?.customer?.last_name}
              </strong>
            </h3>
            <h3 className="text-md">
              Patient Id : <strong>{_this?.customer?.customer_id}</strong>
            </h3>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {_this.workDone ? (
          <h3 className="text-2xl font-bold text-center my-20">
            New payment method added, You can close this window now..
          </h3>
        ) : (
          <Card className="mb-3 flex  mr-2">
            <div className="flex justify-between items-center">
              <h3 className="text-body-color text-xl">Select Payment Method</h3>
              <Row className="w-6/12 flex justify-center mt-2">
                <Checkbox
                  className="mt-2 text-sm"
                  id="do_not_mail"
                  label={<p className="text-base tracking-tight">ACH</p>}
                  type="checkbox"
                  checked={_this?.achPaymentVisibility}
                  onChange={(e) => {
                    if (e.target.checked) {
                      _this?.setACHPaymentVisibility(true);
                      _this?.updateCardDetails('cvc', '');
                      _this?.updateCardDetails('expiry', '');
                      _this?.updateCardDetails('focus', '');
                      _this?.updateCardDetails('name', '');
                      _this?.updateCardDetails('number', '');
                      _this?.updateCardDetails('focused', '');
                    }
                  }}
                />
                <Checkbox
                  className="mt-2 text-sm"
                  id="do_not_mail"
                  label={<p className="text-base tracking-tight">Credit Card</p>}
                  type="checkbox"
                  checked={!_this.achPaymentVisibility}
                  onChange={(e) => {
                    if (e.target.checked) {
                      _this.setACHPaymentVisibility(false);
                      _this.updateACHDetails('name', '');
                      _this.updateACHDetails('routing_number', '');
                      _this.updateACHDetails('account_number', '');
                    }
                  }}
                />
              </Row>
            </div>

            <div className="bg-slate-100 mt-2 p-2 pt-3 rounded">
              {_this.achPaymentVisibility === false ? (
                <>
                  <Cards
                    cvc={_this.cardDetails && _this.cardDetails.cvc}
                    expiry={_this.cardDetails && _this.cardDetails.expiry}
                    focused={_this.cardDetails && _this.cardDetails.focused}
                    name={_this.cardDetails && _this.cardDetails.name}
                    number={_this.cardDetails && _this.cardDetails.number}
                  />
                  <Form className="mt-4">
                    <Input
                      label="Card Number"
                      placeholder=""
                      required
                      type="number"
                      value={(_this.cardDetails && _this.cardDetails.number) || ''}
                      onChange={(e) => _this.updateCardDetails('number', e.target.value)}
                    />
                    <Input
                      label="Card Name"
                      placeholder=""
                      type="text"
                      required
                      value={(_this.cardDetails && _this.cardDetails.name) || ''}
                      onChange={(e) => _this.updateCardDetails('name', e.target.value)}
                    />
                    <Row>
                      <Input
                        label="Card Expiry (MM/YYYY)"
                        placeholder=""
                        type="text"
                        required
                        value={(_this.cardDetails && _this.cardDetails.expiry) || ''}
                        onChange={(e) => setCardExpiry(e.target.value)}
                      />
                      <Input
                        label="CVV"
                        placeholder=""
                        required
                        type="text"
                        value={(_this.cardDetails && _this.cardDetails.cvc) || ''}
                        onChange={(e) => _this.updateCardDetails('cvc', e.target.value)}
                      />
                    </Row>
                  </Form>
                </>
              ) : (
                <>
                  <Form className="mt-4">
                    <Input
                      label="Account Number"
                      placeholder=""
                      required
                      type="number"
                      value={_this.achDetails?.account_number || ''}
                      onChange={(e) => _this.updateACHDetails('account_number', e.target.value)}
                    />
                    <Input
                      label="Bank Routing Number"
                      placeholder=""
                      type="text"
                      required
                      value={_this.achDetails?.routing_number || ''}
                      onChange={(e) => _this.updateACHDetails('routing_number', e.target.value)}
                    />
                    <Input
                      label="Name"
                      placeholder=""
                      type="text"
                      required
                      value={_this.achDetails?.name || ''}
                      onChange={(e) => _this.updateACHDetails('name', e.target.value)}
                    />
                  </Form>
                </>
              )}
            </div>
          </Card>
        )}
        {_this.workDone ? null : (
          <Modal.Footer>
            <Button onClick={() => _this.finalDataSubmitforAddNewPayment()}>
              Add New Payment Method
            </Button>
          </Modal.Footer>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Body;
