import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input, Dropdown } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const CreateRoleModal = ({ _this }) => {
  const [note, setNote] = useState('');

  return (
    <Modal
      show={_this.createRoleModalVisibility}
      onHide={() => _this.setCreateRoleModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Role</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setCreateRoleModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <Input
            label="Role Name"
            placeholder="@Enter Role Name"
            type="text"
            required
            value={_this.createRoleData?.role_name || ''}
            onChange={(e) => _this.updateCreateRoleData('role_name', e.target.value)}
          />

          <Dropdown
            label="Permissions"
            required
            type="text"
            options={_this.permissionList}
            // isClearable
            aria-label="Default select example"
            onChange={(e) => {
              _this.updateCreateRoleData('permissions', e);
            }}
            value={_this.createRoleData?.permissions}
            isMulti
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.submitCreateRoleData();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRoleModal;
