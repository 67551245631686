import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const DeleteDocumentModal = ({ _this }) => {
  return (
    <Modal
      show={_this.deleteDocumentModal}
      onHide={() => _this.setDeleteDocumentModal(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-red-500"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Alert</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setDeleteDocumentModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <h3 className="text-font-label text-lg">
            Are You sure you want to delete{' '}
            <span className="text-black">{_this.deleteDocumentId}</span> this document?
          </h3>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" onClick={() => _this.handleDeleteDocumentSubmit()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDocumentModal;
