import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form, Table } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdPlaylistAddCircle, MdFormatListBulletedAdd, MdLibraryAdd } from 'react-icons/md';
import { IoMdAdd, IoIosAddCircleOutline } from 'react-icons/io';

import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  AutSuggestInput,
  TextArea
} from 'components';

const AcceptOrDenyModal = ({ _this }) => {
  return (
    <Modal
      show={_this?.acceptOrDenyApprovalModal}
      centered
      onHide={() => _this?.setAcceptOrDenyApprovalModal(false)}
      size="lg"
      scrollable
      // className="z-50"
      className="my-20 h-[80%]"
    >
      <Modal.Header>
        <Modal.Title>
          {_this.acceptOrDenyApprovalData?.accept ? 'Accept Approval' : 'Deny Approval'}
        </Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setOpsAndSycleData(null);
            _this.setAcceptOrDenyApprovalModal(false),
              _this.setAcceptOrDenyApprovalData({
                notes: '',
                _id: ''
              });
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <>
          {_this.opsAndSycleData?.ops_data.length > 0 &&
          _this.opsAndSycleData?.sycle_data.length > 0 ? (
            <div className=" flex justify-between">
              {_this.opsAndSycleData?.ops_data.length > 0 && (
                <div className="w-[48%]">
                  <p className="text-lg font-semibold">OPS</p>
                  <Table striped bordered hover size="sm">
                    <thead className="text-sm">
                      <tr>
                        <th>Date</th>
                        <th>Expense Amount</th>
                        <th>Serial No</th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      {_this.opsAndSycleData?.ops_data.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.Date}</td>
                          <td>{item?.Expenses_Amount}</td>
                          <td>{item?.Serial_Number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {_this.opsAndSycleData?.sycle_data.length > 0 && (
                <div className="w-[48%]">
                  <p className="text-lg font-semibold">Sycle</p>
                  <Table striped bordered hover size="sm">
                    <thead className="text-sm">
                      <tr>
                        <th>Date</th>
                        <th>Invoice No</th>
                        <th>Patient Name</th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      {_this.opsAndSycleData?.sycle_data.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.Date}</td>
                          <td>{item?.InvoiceNo}</td>
                          <td>{item?.PatientName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center">No record found in OPS/Sycle</div>
          )}
          <Card className="mb-3 flex  mr-2">
            <Form className="bg-slate-100 flex flex-col items-center justify-center gap-2 p-2 rounded">
              <TextArea
                className="w-[400px]"
                label="Notes"
                placeholder="@Notes"
                rows={2}
                value={_this.acceptOrDenyApprovalData?.notes}
                onChange={(e) =>
                  _this.setAcceptOrDenyApprovalData((prev) => ({
                    ...prev,
                    notes: e.target.value
                  }))
                }
              />
            </Form>
          </Card>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={_this.submitAprroval}
          color={_this.acceptOrDenyApprovalData?.accept ? 'primary' : 'danger'}
        >
          {_this.acceptOrDenyApprovalData?.accept ? 'Accept' : 'Deny'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptOrDenyModal;
