import React from 'react';
import { Card, Button, PermissionWrapper, LineGraph, BarGraph } from 'components';
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { BsThreeDots } from 'react-icons/bs';

const CustomerInformation = ({ _this }) => {
  const customer = _this.customersData;

  const calculatePercentage = (currentNumber, lastMonth) => {
    const difference = currentNumber - lastMonth;
    const result = lastMonth != 0 ? (difference / lastMonth) * 100 : 0;

    if (result < 0) {
      return (
        <div className="flex font-semibold text-rose-600">
          <BsArrowDownShort size={25} /> <span className="text-base">{result.toFixed(2)}%</span>
        </div>
      );
    } else if (isNaN(result)) {
      return '';
    } else {
      return (
        <div className="flex font-semibold text-green-600">
          <BsArrowUpShort size={25} /> <span className="text-base">{result.toFixed(2)}%</span>
        </div>
      );
    }
  };
  const AllCustomers = {
    labels: [
      `current ${customer?.total_customers}`,
      `1 Month Ago ${customer?.last_month_customers}`,
      `3 Months Ago ${customer?.last_3_month_customers}`,
      `6 Months Ago ${customer?.last_6_month_customers}`,
      `12 Months Ago ${customer?.last_12_month_customers}`
    ],
    datasets: [
      {
        label: 'All Customers',
        data: [
          customer?.total_customers,
          customer?.last_month_customers,
          customer?.last_3_month_customers,
          customer?.last_6_month_customers,
          customer?.last_12_month_customers
        ],
        backgroundColor: ['#87bc45', '#b3d4ff', '#ede15b', '#9b19f5', '#e60049']
      }
    ]
  };

  const ActiveCustomers = {
    labels: [
      `current ${customer?.total_active_customers}`,
      `1 Month Ago ${customer?.last_month_active_customers}`,
      `3 Months Ago ${customer?.last_3_month_active_customers}`,
      `6 Months Ago ${customer?.last_6_month_active_customers}`,
      `12 Months Ago ${customer?.last_12_month_active_customers}`
    ],
    datasets: [
      {
        label: 'Active Customers',
        data: [
          customer?.total_active_customers,
          customer?.last_month_active_customers,
          customer?.last_3_month_active_customers,
          customer?.last_6_month_active_customers,
          customer?.last_12_month_active_customers
        ],
        backgroundColor: ['#ede15b', '#e60049', '#b3d4ff', '#87bc45', '#9b19f5'],
        borderRadius: '5px'
      }
    ]
  };

  const ActiveSubscription = {
    labels: [
      `current ${customer?.total_active_subscriptions}`,
      `1 Month Ago ${customer?.last_month_active_subscriptions}`,
      `3 Months Ago ${customer?.last_3_month_active_subscriptions}`,
      `6 Months Ago ${customer?.last_6_month_active_subscriptions}`,
      `12 Months Ago ${customer?.last_12_month_active_subscriptions}`
    ],
    datasets: [
      {
        label: 'Active Subscriptions',
        data: [
          customer?.total_active_subscriptions,
          customer?.last_month_active_subscriptions,
          customer?.last_3_month_active_subscriptions,
          customer?.last_6_month_active_subscriptions,
          customer?.last_12_month_active_subscriptions
        ],
        backgroundColor: ['#b3d4ff', '#e60049', '#87bc45', '#9b19f5', '#ede15b']
      }
    ]
  };

  return (
    <div className="flex flex-col justify-between gap-4">
      <div className="w-full bg-white rounded-xl">
        {/* Heading*/}
        <div className="flex justify-between items-center rounded-xl theme-light-blue px-5 py-3 rounded-t-xl">
          <h1>CUSTOMER INFORMATION</h1>

          <PermissionWrapper permission={'select_customer_information_location'}>
            <div className="flex flex-col sm:flex-row gap-2 justify-center items-center">
              <Form.Select
                className="md:w-48 rounded-md bg-transparent border-theme-dashboard-dark-blue text-theme-dashboard-dark-blue focus:border-button-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  _this.getSubCategoriesFnachiseLocationOptions &&
                    _this.getSubCategoriesFnachiseLocationOptions(
                      _this.keysOfFranchiseLocationData[e.target.value]
                    );
                  _this.setSelectedFieldsForCustomerInformation((prev) => ({
                    ...prev,
                    key: e.target.value
                  }));
                }}
              >
                <option>Select Parameters</option>
                {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                className="w-58 rounded-md border-theme-dashboard-dark-blue text-theme-dashboard-dark-blue bg-transparent focus:border-button-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  _this.onFranchiseLocationOptionsForCustomerSubmit(e.target.value);
                  _this.setSelectedFieldsForCustomerInformation((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              >
                <option>Select Options</option>
                <option>Total</option>
                {_this.subCategoriesFranchiseLocationOptions?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
          </PermissionWrapper>
        </div>

        <Card
          bordered={false}
          className="flex-1 rounded-xl bg-theme-dull-white"
          title="OFFLINE WORK"
          // extra={<BsThreeDots className="text-theme-text-color" />}
        >
          <div className="flex flex-col gap-2">
            <p className="">Active Customers</p>
            <div className="flex flex-col gap-4 h-80">
              <BarGraph data={ActiveCustomers} />
            </div>
          </div>
        </Card>
      </div>

      <Card
        bordered={false}
        className="flex-1 rounded-xl bg-theme-dull-white"
        title="OFFLINE WORK"
        // extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-col gap-2">
          <p className="">Active Subscriptions</p>
          <div className="flex flex-col gap-4 h-80">
            <BarGraph data={ActiveSubscription} />
          </div>
        </div>
      </Card>
      <Card
        bordered={false}
        className="flex-1 rounded-xl bg-theme-dull-white"
        title="OFFLINE WORK"
        // extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-col gap-2">
          <p className="">All Customers</p>
          <div className="flex flex-col gap-4 h-80">
            <BarGraph data={AllCustomers} />
          </div>
        </div>
      </Card>
      {/* <Card
        bordered={false}
        title="ONLINE WORK"
        className="flex-1 rounded-xl bg-theme-dull-white"
        extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-row gap-2">
          <p className="flex basis-1/3">Graph</p>
          <div className="flex basis-2/3 flex-col gap-2">
            <span className="font-extrabold text-2xl text-theme-dull-white">9</span>
            <span className="text-theme-text-secondary-color text-sm">Online Consultations</span>
            <span className="text-sm p-1 bg-green-200 text-green-400 rounded">
              +12% than average
            </span>
          </div>
        </div>
      </Card>
      <Card
        bordered={false}
        title="LABORATORY WORK"
        className="flex-1 rounded-xl bg-theme-dull-white"
        extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-row gap-2">
          <p className="flex basis-1/3">Graph</p>
          <div className="flex basis-2/3 flex-col gap-2">
            <span className="font-extrabold text-2xl text-theme-dull-white">19</span>
            <span className="text-theme-text-secondary-color text-sm">Laboratory Analysis</span>
            <span className="text-sm p-1 bg-cyan-200 text-cyan-400 rounded">+0% than average</span>
          </div>
        </div>
      </Card> */}
    </div>
    // <Card className="flex">
    //   <div className="flex flex-col sm:flex-row justify-between border-b-[3px] pb-1 gap-3">
    //     <h3 className="text-body-color text-xl md:text-2xl">Customer Information</h3>
    //     <PermissionWrapper permission={'select_customer_information_location'}>
    //       <div className="flex flex-col sm:flex-row gap-2 justify-center items-center mb-3">
    //         <Form.Select
    //           className="md:w-48 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.getSubCategoriesFnachiseLocationOptions &&
    //               _this.getSubCategoriesFnachiseLocationOptions(
    //                 _this.keysOfFranchiseLocationData[e.target.value]
    //               );
    //             _this.setSelectedFieldsForCustomerInformation((prev) => ({
    //               ...prev,
    //               key: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Parameters</option>
    //           {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>

    //         <Form.Select
    //           className="w-58 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.onFranchiseLocationOptionsForCustomerSubmit(e.target.value);
    //             _this.setSelectedFieldsForCustomerInformation((prev) => ({
    //               ...prev,
    //               value: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Options</option>
    //           <option>Total</option>
    //           {_this.subCategoriesFranchiseLocationOptions?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>
    //       </div>
    //     </PermissionWrapper>
    //   </div>

    //   <div className="flex justify-between my-2">
    //     <h6 className="text-body-color text-lg font-semibold pb-1 mt-2">All Customers</h6>
    //     <h6 className="text-body-color mt-2 pb-1 text-lg">
    //       As per{' '}
    //       <span className="text-black">
    //         {_this.selectedFieldsforCustomerInformation?.value != '' &&
    //         _this.selectedFieldsforCustomerInformation?.key != 'Total'
    //           ? `${_this.selectedFieldsforCustomerInformation?.key}: ${_this.selectedFieldsforCustomerInformation?.value}`
    //           : 'Total'}
    //       </span>
    //     </h6>
    //   </div>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_customers}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_3_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_6_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_12_month_customers
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <h6 className="text-body-color font-semibold pb-1 mt-4 my-2">Active Customers</h6>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_active_customers}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_3_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_6_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_12_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //   </div>

    //   <h6 className="text-body-color font-semibold pb-1 mt-4 my-2">Active Subscriptions</h6>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_active_subscriptions}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_3_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_6_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_12_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </Card>
  );
};

export default CustomerInformation;
