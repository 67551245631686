import React from 'react';
import { IoMdSettings } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MyProfile = ({ _this }) => {
  return (
    <div className="w-full h-full flex rounded-xl mr-2 flex-col sm:flex-row md:flex-col">
      {/* profile card*/}
      <div className="w-full bg-white rounded-xl">
        {/* Heading*/}
        <div className="flex px-3 py-2 justify-between items-center rounded-xl theme-light-blue text-theme-dull-white rounded-t-xl">
          <h1>MY PROFILE</h1>
          <Link to={'/profile'}>
            <div className="p-2 bg-transparent border-1 border-theme-dashboard-dark-blue rounded-lg">
              <IoMdSettings className="text-theme-dashboard-dark-blue cursor-pointer" size={22} />
            </div>
          </Link>
        </div>
        {/* profile*/}

        <div className="w-full flex p-2 py-8 px-4 justify-between">
          <FaUserCircle size={80} className="text-theme-dashboard-blue" />

          {/* <Avatar size={80} icon={<UserOutlined />} /> */}
          <div className="flex flex-col pr-3">
            <p className="text-lg font-bold">{_this.user?.name}</p>
            <p className="text-sm">{_this.user?.role}</p>
          </div>
        </div>

        {/* Info*/}
        <div className="flex bg-white rounded-b-xl p-2 justify-evenly text-center text-xs">
          <div className="border-r-2 border-theme-dull-white pr-4">
            <p>Email</p>
            <p>{_this.user.email}</p>
          </div>

          <div className="border-theme-dull-white pr-4">
            <p>Location</p>
            <p className="text-xs">
              {_this.user?.location
                ?.map((item, index) => {
                  if (index === location.length - 1) {
                    return item;
                  }
                  return item + ',';
                })
                .join('')}
            </p>
          </div>
        </div>
      </div>
      {/* Calendar card (just placeholder for now)*/}
      {/* <div className="w-full bg-gray-400 rounded-xl p-2">Calendar Placeholder</div> */}
    </div>
  );
};

export default MyProfile;
