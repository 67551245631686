import moment from 'moment';
import React from 'react';
import doctorImg from '../../../assets/images/doctor_img.png';

function GoodDayHero({ _this }) {
  return (
    <div className="w-full rounded-xl bg-theme-dashboard-blue text-theme-dull-white h-full px-5 py-2 flex flex-row justify-between">
      <div className="flex flex-col justify-end gap-2">
        <h2 className="text-3xl font-extrabold">Good Day, {_this.user?.name}</h2>
        <p className="text-lg">Have a Nice {moment().format('dddd')}!</p>
      </div>
      <div className="w-2/6 flex justify-center items-center">
        <img src={doctorImg} className="object-cover" alt="hspp-logo" />
      </div>
    </div>
  );
}

export default GoodDayHero;
