import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toggleSideNav } from 'redux/action';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { PermissionWrapper, HasPermission, AutSuggestInput } from 'components';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import API from 'services/api';

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isExpanded = useSelector((state) => state.session).sidenavIsExpanded;
  const user = useSelector((state) => state.session).userSession.email;
  const [userInput, setUserInput] = useState('');
  const [isSuggestionShowing, setIsSuggestionShowing] = useState(false);
  const [autoSuggestList, setAutoSuggestList] = useState([]);
  const inputRef = useRef(null);

  const getPatientDetails = async () => {
    const response = await API.getCustomerIDs(userInput);
    if (response) {
      setAutoSuggestList(response);
    }
  };

  useEffect(() => {
    if (userInput.length == 0) setAutoSuggestList([]);
    else {
      const timer = setTimeout(() => {
        if (inputRef.current.value == userInput) {
          getPatientDetails();
        }

        clearTimeout(timer);
      }, 300);
    }
  }, [userInput]);

  return (
    <>
      <nav
        style={{ zIndex: 9999 }}
        className="flex flex-row justify-between items-center fixed top-0 left-0 w-full h-[70px] bg-body-backgroudColor"
      >
        <div className="flex flex-row items-center">
          <div className="cursor-pointer mx-4 w-[50px]" onClick={() => dispatch(toggleSideNav())}>
            <div
              className={classNames(
                'bg-body-color h-[3px] mb-1 transition-all duration-300',
                isExpanded ? 'w-[25px]' : 'w-[22px]'
              )}
            ></div>
            <div
              className={classNames(
                'bg-body-color h-[3px] mb-1 transition-all duration-300',
                isExpanded ? 'w-[25px]' : 'w-[15px]'
              )}
            ></div>
            <div
              className={classNames(
                'bg-body-color h-[3px] mb-1 transition-all duration-300',
                isExpanded ? 'w-[25px]' : 'w-[22px]'
              )}
            ></div>
          </div>

          <div className="font-sans-serif mr-4 text-button-primary sm:text-2xl text-sm md:text-lg font-semibold">
            HSPP Dashboard
          </div>
        </div>
        <div className="flex gap-4 justify-center items-center mx-3">
          <PermissionWrapper permission={'search_patient'}>
            <div className="w-32 sm:w-60 relative ">
              <input
                type="search"
                className="w-full"
                placeholder="Patient Id/Name"
                value={userInput}
                ref={inputRef}
                onChange={(w) => {
                  if (w.target.value == '') setIsSuggestionShowing(false);
                  else setIsSuggestionShowing(true);
                  setUserInput(w.target.value);
                }}
              />
              <div
                className={`absolute bg-white w-full rounded max-h-72 ${
                  autoSuggestList?.length ? 'border-[1px] ' : ' '
                } border-gray-500 overflow-y-auto`}
              >
                {isSuggestionShowing &&
                  autoSuggestList?.map((item, i) => {
                    return (
                      <div
                        className="p-2 border-b border-gray-300"
                        key={i}
                        onClick={() => {
                          setIsSuggestionShowing(false);
                          setUserInput('');
                          navigate(`/customer-info/${item.id}`);
                        }}
                      >
                        <span>Name : {item.full_name}</span>
                        <br />
                        <span>Customer ID : {item.customer_id}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </PermissionWrapper>
          {/* <div className="flex justify-center items-center mr-2 text-3xl w-16 h-16 rounded-full border-3 border-gray-400 text-button-primary">
            {user[0].toString().toUpperCase()}
          </div> */}
        </div>
      </nav>
      <div className="h-[70px]"></div>
    </>
  );
};

Component.propTypes = {};

export default Component;
