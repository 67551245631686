import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import Body from './Body';
import API from 'services/api';
import { PermissionWrapper, HasPermission } from 'components';

const EditCustomer = ({ _this }) => {
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState(_this.customerDetails);
  const [shippingAddress, setShippingAddress] = useState(_this.customerDetails?.shipping_address);

  const updateCustomerDetails = (key, value) => {
    setCustomerDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const updateShippingAddress = (key, value) => {
    setShippingAddress((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  return (
    <Modal
      className="mt-10"
      show={_this.editCustomerModalOpen}
      onHide={() => {
        setCustomerDetails(_this.customerDetails);
        setShippingAddress(_this.customerDetails?.shipping_address);
        _this.setEditCustomerModalOpen(false);
      }}
      size="xl"
      fullscreen="xl"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="mt-10 mb-16"
      // centered
    >
      <Modal.Header className="">
        <Modal.Title id="contained-modal-title-vcenter">Customer Details</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditCustomerModalOpen(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-col md:flex-row gap-2">
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Contact Information</h3>
          <Form className="bg-slate-100 mt-2 p-2 rounded">
            <Input
              label="Customer ID"
              placeholder="@Enter ID"
              type="text"
              required
              value={(customerDetails && customerDetails._id) || ''}
              disabled
            />
            <Row>
              <Input
                label="First Name"
                placeholder="@Enter first name"
                type="text"
                required
                value={(customerDetails && customerDetails.first_name) || ''}
                onChange={(e) => updateCustomerDetails('first_name', e.target.value)}
              />
              <Input
                label="Last Name"
                placeholder="@Enter last name"
                type="text"
                required
                value={(customerDetails && customerDetails.last_name) || ''}
                onChange={(e) => updateCustomerDetails('last_name', e.target.value)}
              />
            </Row>
            <Row>
              <Input
                label="Email"
                placeholder="@Enter email"
                type="text"
                value={(customerDetails && customerDetails.email) || ''}
                onChange={(e) => updateCustomerDetails('email', e.target.value)}
              />
              <Input
                label="Phone"
                placeholder="@Enter phone"
                type="text"
                required
                value={(customerDetails && customerDetails.phone) || ''}
                onChange={(e) => updateCustomerDetails('phone', e.target.value)}
              />
            </Row>
            <Row>
              <Input
                label="Signature Here"
                placeholder=""
                required
                value={(customerDetails && customerDetails?.signature?.signature) || ''}
                onChange={(e) =>
                  updateCustomerDetails('signature', {
                    signature: e.target.value,
                    font: 'default'
                  })
                }
                className="signature w-52"
              />
              <PermissionWrapper permission="edit_patient_id">
                <Input
                  label="Patient ID"
                  placeholder="@Enter ID"
                  type="text"
                  required
                  value={(customerDetails && customerDetails?.customer_id) || ''}
                  onChange={(e) => {
                    updateCustomerDetails('customer_id', e.target.value);
                  }}
                />
              </PermissionWrapper>
            </Row>
          </Form>
        </Card>
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Shipping Information</h3>
          <Form className="bg-slate-100 mt-2 p-2 rounded">
            <Input
              label="Address 1"
              placeholder=""
              type="text"
              required
              value={(shippingAddress && shippingAddress.address1) || ''}
              onChange={(e) => updateShippingAddress('address1', e.target.value)}
            />
            <Row>
              <Input
                label="Address 2"
                placeholder=""
                type="text"
                value={(shippingAddress && shippingAddress.address2) || ''}
                onChange={(e) => updateShippingAddress('address2', e.target.value)}
              />
              <Input
                label="Shipping Contact"
                placeholder=""
                required
                type="text"
                value={(shippingAddress && shippingAddress.contact) || ''}
                onChange={(e) => updateShippingAddress('contact', e.target.value)}
              />
            </Row>
            <Row>
              <Input
                label="City"
                placeholder=""
                type="text"
                required
                value={(shippingAddress && shippingAddress.city) || ''}
                onChange={(e) => updateShippingAddress('city', e.target.value)}
              />
              <Dropdown
                label="State"
                placeholder="Select"
                required
                type="text"
                options={_this.stateList}
                value={{ label: shippingAddress?.state, value: shippingAddress?.state }}
                onChange={(e) => updateShippingAddress('state', e.value)}
              />
            </Row>
            <Row>
              <Dropdown
                label="Country"
                placeholder="Select"
                required
                type="text"
                isDisabled
                defaultValue={shippingAddress?.country}
                value={{ label: shippingAddress?.country, value: shippingAddress?.country }}
                onChange={(e) => updateShippingAddress('country', e)}
              />
              <Input
                label="Zipcode"
                placeholder=""
                required
                type="text"
                value={(shippingAddress && shippingAddress.zipcode) || ''}
                onChange={(e) => updateShippingAddress('zipcode', e.target.value)}
              />
            </Row>
          </Form>
          <Checkbox
            className="mt-2 text-sm"
            label="Do not mail"
            type="checkbox"
            id={'do_not_mail'}
            checked={shippingAddress && shippingAddress.do_not_mail}
            onChange={(e) => {
              updateShippingAddress('do_not_mail', e.target.checked);
            }}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            customerDetails.shipping_address = shippingAddress;
            _this.updateDetails('shipping', customerDetails?._id, customerDetails);
          }}
        >
          Update Details
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCustomer;

//test
