import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdPlaylistAddCircle, MdFormatListBulletedAdd, MdLibraryAdd } from 'react-icons/md';
import { IoMdAdd, IoIosAddCircleOutline } from 'react-icons/io';
import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  AutSuggestInput
} from 'components';

const AddSerialNumberModal = ({ _this }) => {
  return (
    <Modal
      show={_this?.addSerialNumberModalVisibility}
      centered
      onHide={() => _this?.setAddSerialNumberModalVisibility(false)}
      size="xl"
      // className="z-50"
      className="my-20"
    >
      <Modal.Header>
        <Modal.Title>Add Serial Number</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this.setAddSerialNumberModalVisibility(false),
              _this.setAddSerialNumberData({
                left: null,
                right: null
              });
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <Form className="bg-slate-100 flex flex-col items-center justify-center gap-2 p-2 rounded">
            <Row className="flex flex-col sm:flex-row">
              <Input
                //   className="w-48"
                label="Left Serial Number"
                placeholder="@Left Serial Number"
                type="text"
                value={_this.addSerialNumberData?.left?.serial_number}
                onChange={(e) =>
                  _this.setAddSerialNumberData((prev) => ({
                    ...prev,
                    left: {
                      ...prev.left,
                      serial_number: e.target.value
                    }
                  }))
                }
              />
              <DatePicker
                //   className="w-48"
                label="Left Purchase Date"
                placeholder="@Left Purchase Date"
                value={_this.addSerialNumberData?.left?.purchase_date}
                onChange={(e) =>
                  _this.setAddSerialNumberData((prev) => ({
                    ...prev,
                    left: {
                      ...prev.left,
                      purchase_date: e
                    }
                  }))
                }
                // required
                // disabled
              />
            </Row>

            <Row className="flex flex-col sm:flex-row">
              <Input
                //   className="w-48"
                label="Right Serial Number"
                placeholder="@Right Serial Number"
                type="text"
                value={_this.addSerialNumberData?.right?.serial_number}
                onChange={(e) =>
                  _this.setAddSerialNumberData((prev) => ({
                    ...prev,
                    right: {
                      ...prev.right,
                      serial_number: e.target.value
                    }
                  }))
                }
              />
              <DatePicker
                //   className="w-48"
                label="Right Purchase Date"
                placeholder="@Right Purchase Date"
                value={_this.addSerialNumberData?.right?.purchase_date}
                onChange={(e) =>
                  _this.setAddSerialNumberData((prev) => ({
                    ...prev,
                    right: {
                      ...prev.right,
                      purchase_date: e
                    }
                  }))
                }
              />
            </Row>
          </Form>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={_this.submitAddSerialNumberData}>Add</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSerialNumberModal;
