import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  AutSuggestInput
} from 'components';
import { Form, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RiQuestionFill } from 'react-icons/ri';
import ButtonBtn from 'react-bootstrap/Button';
import SubscriptionDetails from './SubscriptionDetails';
import './style.scss';
import moment from 'moment';

const Section = ({ _this }) => {
  return (
    <>
      <div className="flex md:flex-row mb-2 flex-col gap-y-5 gap-x-4">
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Contact Information</h3>
          <Form
            className="bg-slate-100 mt-2 p-2 rounded"
            // onBlur={() => _this.CheckCustomerExists()}
          >
            <AutSuggestInput
              onSearch={_this.loadCustomerOptions}
              onSelect={_this.updateCustomerDetailsBySelection}
              label={'Patient ID'}
              controlId={null}
              isLoading={_this.isPatientIdLoading}
              items={_this.autoSuggestList}
              placeholder={
                _this.customerDetails?.customer_id || '@Please enter the Sycle ID of the patient'
              }
              onBlur={() => {
                _this.CheckCustomerExists();
              }}
            />
            <Row>
              <Input
                label="First Name"
                placeholder="@Please enter the first name of the patient"
                type="text"
                required
                value={_this.customerDetails?.first_name}
                onChange={(e) => {
                  _this.updateCustomerDetails('first_name', e.target.value);
                }}
                onBlur={(e) => {
                  _this.updateCustomerDetails('signature', {
                    signature:
                      _this.customerDetails?.first_name + ' ' + _this.customerDetails?.last_name,
                    font: 'default'
                  });
                }}
              />
              <Input
                label="Last Name"
                placeholder="@Please enter the last name of the patient"
                type="text"
                required
                value={_this.customerDetails?.last_name}
                onChange={(e) => {
                  _this.updateCustomerDetails('last_name', e.target.value);
                }}
                onBlur={(e) => {
                  _this.updateCustomerDetails('signature', {
                    signature:
                      _this.customerDetails?.first_name + ' ' + _this.customerDetails?.last_name,
                    font: 'default'
                  });
                }}
              />
            </Row>
            <Row>
              <Input
                label="Email"
                placeholder="@Please enter the email address of the patient"
                type="text"
                value={_this.customerDetails?.email}
                onChange={(e) => _this.updateCustomerDetails('email', e.target.value)}
                onBlur={() => {
                  _this.CheckCustomerExists();
                }}
              />
              <Input
                label="Phone"
                placeholder="@Please enter the Phone Number of the patient"
                type="text"
                required
                value={_this.customerDetails?.phone}
                onChange={(e) => _this.updateCustomerDetails('phone', e.target.value)}
                onBlur={() => {
                  _this.CheckCustomerExists();
                }}
              />
            </Row>
            <Row>
              <Input
                label="Signature Here"
                placeholder=""
                required
                value={_this.customerDetails?.signature?.signature}
                onChange={(e) =>
                  _this.updateCustomerDetails('signature', {
                    signature: e.target.value,
                    font: 'default'
                  })
                }
                className="signature w-52"
              />
              <Input
                required
                label="HSPP Authorization Form"
                placeholder=""
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  _this.setFileAttachment(selectedFile);
                }}
              />
            </Row>
            <Dropdown
              label={
                <div className="flex flex-row items-center">
                  <div>
                    Number Of Subscription <span className="text-red-600"> * </span>
                  </div>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        <p>
                          Generally customer will have one subscription, multiple subscriptions can
                          occur when a spouse is purchasing a subscription for the other spouse
                        </p>
                      </Tooltip>
                    }
                  >
                    <span
                      className={classNames(
                        'border-2 border-slate-600 bg-transparent rounded-2xl text-slate-500 hover:text-slate-500 relative ml-3 p-0'
                      )}
                    >
                      <RiQuestionFill className="h-3 w-3 " />
                    </span>
                  </OverlayTrigger>
                </div>
              }
              type="text"
              options={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 }
              ]}
              defaultValue={_this.noOfPlans}
              onChange={_this.onNoOfPlanChange}
            />
          </Form>
        </Card>
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Shipping Information</h3>
          <Form className="bg-slate-100 mt-2 p-2 rounded">
            <Input
              label="Address 1"
              placeholder=""
              type="text"
              required
              value={(_this.shippingAddress && _this.shippingAddress.address1) || ''}
              onChange={(e) => _this.updateShippingAddress('address1', e.target.value)}
            />
            <Row>
              <Input
                label="Address 2"
                placeholder=""
                type="text"
                value={(_this.shippingAddress && _this.shippingAddress.address2) || ''}
                onChange={(e) => _this.updateShippingAddress('address2', e.target.value)}
              />
              <Input
                label="Shipping Contact"
                placeholder=""
                required
                type="text"
                value={(_this.shippingAddress && _this.shippingAddress.contact) || ''}
                onChange={(e) => _this.updateShippingAddress('contact', e.target.value)}
              />
            </Row>
            <Row>
              <Input
                label="City"
                placeholder=""
                type="text"
                required
                value={(_this.shippingAddress && _this.shippingAddress.city) || ''}
                onChange={(e) => _this.updateShippingAddress('city', e.target.value)}
              />
              <Dropdown
                label="State"
                placeholder="Select"
                required
                type="text"
                options={_this.stateList}
                value={
                  _this.shippingAddress && _this.shippingAddress.state
                    ? { label: _this.shippingAddress.state, value: _this.shippingAddress.state }
                    : ''
                }
                onChange={(e) => _this.updateShippingAddress('state', e.value)}
              />
            </Row>
            <Row>
              <Dropdown
                label="Country"
                placeholder="Select"
                required
                type="text"
                isDisabled
                defaultValue={(_this.shippingAddress && _this.shippingAddress.country) || ''}
                value={(_this.shippingAddress && _this.shippingAddress.country) || ''}
                onChange={(e) => _this.updateShippingAddress('country', e)}
              />
              <Input
                label="Zipcode"
                placeholder=""
                required
                type="text"
                value={(_this.shippingAddress && _this.shippingAddress.zipcode) || ''}
                onChange={(e) => _this.updateShippingAddress('zipcode', e.target.value)}
              />
            </Row>
          </Form>
          <Row>
            <Checkbox
              className="mt-2 text-sm"
              id="do_not_mail"
              label={
                <div className="flex flex-row items-center">
                  <div>Do not mail</div>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        <p>If selected the customer will not receive multiple batteries</p>
                      </Tooltip>
                    }
                  >
                    <span
                      className={classNames(
                        'border-2 border-slate-600 bg-transparent rounded-2xl text-slate-500 hover:text-slate-500 relative ml-3 p-0'
                      )}
                    >
                      <RiQuestionFill className="h-3 w-3 " />
                    </span>
                  </OverlayTrigger>
                </div>
              }
              type="checkbox"
              checked={_this.shippingAddress?.do_not_mail || false}
              onChange={(e) => {
                _this.updateShippingAddress('do_not_mail', e.target.checked);
              }}
            />
          </Row>
        </Card>
      </div>

      {Array(_this.noOfPlans.value)
        .fill(0)
        .map((item, index) => (
          <SubscriptionDetails key={index} i={index} _this={_this} />
        ))}

      <Modal
        show={_this.onNextClickModalVisibility}
        onHide={() => _this.setOnNextClickModalVisibility(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-full">
            <h3 className="text-center">Please confirm details</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-16">
          <div className="font-semibold px-8 text-xl flex gap-5 justify-center">
            {_this.customerDetails?.email ? (
              <div>Patient Email : {_this.customerDetails?.email}</div>
            ) : null}
            <div>phone no : {_this.customerDetails?.phone}</div>
          </div>

          {_this?.productInformation?.map((product, i) => (
            <div key={i} className="mt-3">
              <hr />
              <h3 className="text-xl text-center">
                Product <strong>{i + 1}</strong> details
              </h3>
              <div className="flex justify-center text-lg px-8">
                <span className="w-1/2 text-left">
                  Left warranty date :{' '}
                  <strong>{moment(product?.l_warranty_expiry)?.format('YYYY-MM-DD')}</strong>
                </span>
                <span className="w-1/2 text-left">
                  Right warranty date :{' '}
                  <strong>{moment(product?.r_warranty_expiry)?.format('YYYY-MM-DD')}</strong>
                </span>
              </div>
              <div className="flex justify-center text-lg px-8">
                <span className="w-1/2 text-left">
                  Left battery size : <strong>{product?.l_battery_size?.label}</strong>
                </span>
                <span className="w-1/2 text-left">
                  Right battery size : <strong>{product?.r_battery_size?.label}</strong>
                </span>
              </div>
              <div className="flex justify-center text-lg px-8">
                <span className="w-1/2 text-left">
                  Left serial no :{' '}
                  <strong>{product?.left_serial ? product?.left_serial : '__'}</strong>
                </span>
                <span className="w-1/2 text-left">
                  Right serial no :{' '}
                  <strong>{product?.right_serial ? product?.right_serial : '__'}</strong>
                </span>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <ButtonBtn
            variant="outline-primary"
            className="px-4"
            onClick={() => {
              _this.setOnNextClickModalVisibility(false);
              _this.setActiveStep(_this.activeStep + 1);
            }}
          >
            Yes
          </ButtonBtn>
          <ButtonBtn
            variant="outline-secondary"
            className="px-4"
            onClick={() => {
              _this.setOnNextClickModalVisibility(false);
            }}
          >
            Close
          </ButtonBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Section;
