import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { logout, toggleMenu } from 'redux/action';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AiFillDownCircle } from 'react-icons/ai';
import tabs from 'navigation/tabs';
import { toggleSideNav } from 'redux/action';
const Component = ({ children, ...props }) => {
  const isExpanded = useSelector((state) => state.session).sidenavIsExpanded;
  const user = useSelector((state) => state.session).userSession;
  // const tabs =
  //   // user.role == 'SuperAdmin'
  //   [
  //     { name: 'Dashboard', to: '/dashboard', icon: MdDashboard, permission: 'view_dashboard' },
  //     { name: 'Manage Roles', to: '/manage-roles', icon: MdManageAccounts },
  //     { name: 'Mail', to: '/mail', icon: GrMail },
  //     { name: 'Customers', to: '/customer-list', icon: MdPeople },
  //     { name: 'Employees', to: '/employee-list', icon: BsBriefcaseFill },
  //     { name: 'Plans', to: '/plan-list', icon: MdArticle },
  //     { name: 'Subscriptions', to: '/subscription-list', icon: BsJournalBookmarkFill },
  //     { name: 'Invoices', to: '/invoice-list', icon: FaFileInvoice },
  //     { name: 'Profile', to: '/profile', icon: HiUserCircle },
  //     { name: 'Recycle Bin', to: '/deleted-customer-list', icon: MdDelete },
  //     { name: 'Logout', to: '/', icon: RiLogoutBoxFill }
  //   ];
  // : user.role == 'Employee'
  // ? [
  //     { name: 'Dashboard', to: '/dashboard', icon: MdDashboard },
  //     { name: 'Customers', to: '/customer-list', icon: MdPeople },
  //     { name: 'Invoices', to: '/employee/invoice-list', icon: FaFileInvoice },
  //     {
  //       name: 'Subscriptions',
  //       to: '/employee/subscription-list',
  //       icon: BsJournalBookmarkFill
  //     },
  //     { name: 'Profile', to: '/employee/profile', icon: HiUserCircle },
  //     { name: 'Logout', to: '/', icon: RiLogoutBoxFill }
  //   ]
  // : [
  //     { name: 'Dashboard', to: '/customer/dashboard', icon: MdDashboard },
  //     { name: 'Profile', to: '/customer/profile', icon: HiUserCircle },
  //     { name: 'Logout', to: '/', icon: RiLogoutBoxFill }
  //   ];

  return (
    <div>
      <Header />
      <div className="flex flex-row">
        <div
          className={classNames(
            'transition-all duration-300 sm:py-3 sm:ml-4 sm:mr-3',
            isExpanded ? 'w-[220px]' : 'hidden sm:block sm:w-[70px]'
          )}
        >
          <div
            className={classNames(
              'fixed z-50 bg-theme-dashboard-blue rounded-2xl max-h-[80vh] mt-3 py-3 transition-all duration-300 overflow-y-auto donot-show-scrollbar sm:pb-20',
              isExpanded ? 'w-full sm:w-[220px]' : 'w-[70px]'
            )}
          >
            <ul className="px-4 text-theme-dull-white pt-2">
              {tabs
                .filter((item) => user.permissions.includes(item.permission))
                .map((e, i) => (
                  <MenuItem
                    key={i}
                    label={e.name}
                    isExpanded={isExpanded}
                    // isActive={true}
                    Icon={e.icon}
                    to={e.to}
                  />
                ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 min-w-0 min-h-screen pl-2 pr-2 pt-2">{children}</div>
      </div>
    </div>
  );
};

Component.propTypes = {
  children: PropTypes.node
};

export default Component;

const MenuItem = ({ label, isExpanded, Icon, to, subMenuItems = [] }) => {
  const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.session).selectedMenu;

  if (to)
    return (
      <div className={`mb-2.5`}>
        <Link
          to={to}
          className="text-theme-dull-white"
          onClick={() => {
            label == 'Logout' ? dispatch(logout()) : dispatch(toggleMenu(label));
            const screenWidth = window.innerWidth;
            screenWidth < 640 ? dispatch(toggleSideNav()) : null;
          }}
        >
          <li className="hover:text-white cursor-pointer font-medium">
            <div className="flex flex-row items-center">
              <span className="text-2xl">
                <Icon className="mr-3" />
              </span>{' '}
              <span
                className={classNames(
                  'transition-all duration-300 font-medium',
                  isExpanded ? '' : 'hidden'
                )}
              >
                {label}
              </span>
            </div>
          </li>
        </Link>
      </div>
    );
  else
    return (
      <div className="text-theme-dull-white mb-2.5">
        <li className="hover:text-white cursor-pointer font-medium">
          <div
            onClick={() => {
              dispatch(toggleMenu(selectedMenu === label ? '' : label));
            }}
            className="flex flex-row items-center justify-between"
          >
            <div className="flex flex-row items-center">
              <span className="text-2xl">
                <Icon className="mr-3" />
              </span>{' '}
              <span
                className={classNames(
                  'transition-all duration-300 font-medium',
                  isExpanded ? '' : 'hidden'
                )}
              >
                {label}
              </span>
            </div>
            <AiFillDownCircle className="" />
          </div>
          {isExpanded && selectedMenu === label && (
            <ul className="ml-9 mt-1.5 text-theme-dull-white">
              {subMenuItems.map((e, i) => {
                return (
                  <SubMenuItem
                    key={i}
                    label={e.label}
                    isExpanded={isExpanded}
                    isActive={true}
                    to={e.to}
                  />
                );
              })}
            </ul>
          )}
        </li>
      </div>
    );
};

const SubMenuItem = ({ label, isExpanded, to }) => {
  return (
    <div className="mb-1.5">
      <Link to={to} className="text-theme-dull-white">
        <li className="hover:text-white cursor-pointer">
          <div className="flex flex-row items-center">
            <span className="text-2xl"></span>{' '}
            <span
              className={classNames(
                'transition-all duration-300 font-medium text-sm',
                isExpanded ? '' : 'hidden'
              )}
            >
              {label}
            </span>
          </div>
        </li>
      </Link>
    </div>
  );
};
