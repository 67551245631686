import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import JsonView from 'react-json-view';

const LogDataModal = ({ _this }) => {
  let [isJson, setIsJson] = useState(false);
  let [content, setContent] = useState('');

  useEffect(() => {
    try {
      let jsonData = JSON.parse(_this?.logDataToShow?.data);
      if (typeof jsonData == 'object') setIsJson(true);
      else setIsJson(false);
    } catch (error) {
      setIsJson(false);
    }
    setContent(_this.logDataToShow?.data);
  }, [_this?.logDataToShow?.data]);

  const closeModal = () => {
    _this.setLogDataToShow(null);
    _this?.setSeeLogDataModalOpen(false);
    setIsJson(false);
  };

  return (
    <Modal
      show={_this?.seeLogDataModalOpen}
      onHide={closeModal}
      size="lg"
      fullscreen="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-16 h-[90%]"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Log data for {_this?.logDataToShow?.action}
        </Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this?.setSeeLogDataModalOpen(false)} />
      </Modal.Header>
      <Modal.Body className="px-3">
        <Card className="mb-3 flex flex-col gap-4 mr-2">
          {isJson ? <JsonView src={JSON.parse(content)} /> : <h3 className="text-xl">{content}</h3>}
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal()}>Go Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogDataModal;
