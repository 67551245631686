import React, { useEffect } from 'react';
import { Card, Button, Input } from 'components';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Checkbox } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaPaypal, FaCheckCircle, FaTimes, FaRecycle } from 'react-icons/fa';
import { BiPlus, BiEditAlt } from 'react-icons/bi';
import { TbFileInvoice } from 'react-icons/tb';
import { MdDelete } from 'react-icons/md';
import { FcShipped, FcInfo } from 'react-icons/fc';
import EditCustomer from './EditCustomer';
import AddNote from './AddNote';
import SubscriptionDetails from './SubscriptionDetails';
import AddNewPlan from './AddNewPlan';
import AddNewSubscription from './AddNewSubscription';
import Config from 'config';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import EditPaymentDetails from './EditPaymentDetails';
import AddNewPayment from './AddNewPayment';
import SetPrimaryPaymentModal from './SetPrimaryPaymentModal';
import Alert from 'react-bootstrap/Alert';
import RefundModal from './RefundModal';
import EditSubscription from './EditSubscription';
import DocumentModel from './DocumentModel';
import AddCredit from './AddCredit';
import { PermissionWrapper, HasPermission } from 'components';
import RefundDuplicateInvoiceModal from './RefundDuplicateInvoiceModal';
import TemporaryEditSubscription from './TemporaryEditSubscription';
import DeleteSubscriptionModal from './DeleteSubscriptionModal';
import DeleteInvoiceModal from './DeleteInvoiceModal';
import EditDueDateModal from './EditDueDateModal';
import PayExtra from './PayExtra';
import SeeLogEmailDataModal from './SeeLogEmailDataModal';
import { MdArrowBack } from 'react-icons/md';
import DeletePaymentMethodModal from './DeletePaymentMethodModal';
import { toast } from 'react-toastify';
import LogDataModal from './LogDataModel';
import { IoMdTime } from 'react-icons/io';
import DeleteDocumentModal from './DeleteDocumentModal';
import Spinner from 'react-bootstrap/Spinner';
const stripe_link =
  Config?.ENVIRONMENT == 'DEV'
    ? 'https://dashboard.stripe.com/test/disputes/'
    : 'https://dashboard.stripe.com/disputes/';

const Section = ({ _this }) => {
  const customer = _this.customerDetails;
  const navigate = useNavigate();

  const tooltipUI = (row) => {
    if (row.original.status === 'Failed') {
      return (
        <div>
          {JSON.parse(row.original?.payment_gateway_response)?.last_payment_error?.message ||
            JSON.parse(row.original?.payment_gateway_response)?.message ||
            JSON.parse(row.original?.payment_gateway_response)?.failure_message}
        </div>
      );
    } else if (row.original.status === 'Paid' || row.original.status === 'Refunded') {
      const info = row.original?.refunded_data?.map((item, i) => {
        return (
          <div key={i}>
            <p>
              {moment(item.refunded_on).format('YYYY-MM-DD')} | ${item.amount} | {item.status}
            </p>
            <p>--{item.reason ? item.reason : ''}--</p>
          </div>
        );
      });
      return info;
    }

    return null;
  };

  const checkIfAnySubscriptionActive = () => {
    const subscriptionArray = customer.subscription_groups.map((item) => {
      return item.subscriptions.map((elem) => elem.status);
    });

    if (subscriptionArray.map((item) => item.includes('Active') || item.includes('OnHold'))) {
      return true;
    }
    return false;
  };

  const logs_table_columns = [
    {
      Header: 'Date',
      width: 160,
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD, h:mm a')}</div>
    },
    {
      accessor: 'action',
      Header: 'Action',
      width: 150,
      Cell: (row) => <div>{row.original.action}</div>
    },
    {
      accessor: 'data',
      Header: 'Data',
      Cell: (row) => {
        // let content;
        // try {
        //   const parsedData = JSON.parse(row?.original.data);
        //   if (parsedData) {
        //     let amount = Number(parsedData?.credit_balance);
        //     amount = amount > 0 ? amount : amount * -1;
        //     if (parsedData.credit_remarks && parsedData.credit_remarks !== '') {
        //       content = (
        //         <div className="flex justify-center items-center gap-2">
        //           <OverlayTrigger
        //             placement="top"
        //             overlay={
        //               <Tooltip id="tooltip-bottom">
        //                 <p>Remarks: {parsedData?.credit_remarks}</p>
        //               </Tooltip>
        //             }
        //           >
        //             <p className="cursor-pointer">
        //               <FcInfo size={20} />
        //             </p>
        //           </OverlayTrigger>
        //           <p>{'$' + amount}</p>
        //         </div>
        //       );
        //     } else {
        //       content = '$' + (amount || row?.original?.data);
        //     }
        //   }
        // } catch (error) {
        //   // Fix Formatting
        //   // content = row?.original?.data;
        // }
        return (
          <div className="flex justify-center">
            {row?.original?.data ? (
              row.original.action.includes('Email') ? (
                <button
                  className="bg-button-primary hover:bg-button-primaryHover text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
                  onClick={() => {
                    _this.setSeeLogEmailDataModalOpen(true),
                      _this.setLogEmailData((prev) => ({
                        ...prev,
                        _id: row?.original?._id,
                        data: row?.original?.data,
                        createdAt: row?.original?.createdAt
                      }));
                  }}
                >
                  View
                </button>
              ) : (
                <button
                  className="bg-button-primary hover:bg-button-primaryHover text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
                  onClick={() => {
                    _this.setSeeLogDataModalOpen(true);
                    _this.setLogDataToShow({
                      action: row?.original?.action,
                      data: row?.original?.data
                    });
                  }}
                >
                  View
                </button>
              )
            ) : //  : row.original.action == 'RefundInitiated' ? (
            //   row?.original?.data ? (
            //     <div> ${JSON.parse(row?.original?.data)?.refund_amount}</div>
            //   ) : null
            // ) : (
            //   <div> {content}</div>
            // )
            null}
          </div>
        );
      }
    },
    {
      accessor: 'executed_by',
      Header: 'By',
      width: 120,
      Cell: (row) => (
        <div>{row.original.executed_by == 'Customer' ? 'Self' : row.original.employee.name}</div>
      )
    }
  ];

  const chargeback_table = [
    {
      Header: 'Dispute On',
      sortable: false,
      minWidth: 155,
      Cell: (row) => (
        <div>
          {row?.original?.data &&
            (JSON?.parse(row?.original?.data)?.created
              ? moment.unix(JSON?.parse(row?.original?.data)?.created)?.format('YYYY-MM-DD')
              : '--')}
        </div>
      )
    },
    {
      Header: 'Dispute',
      accessor: 'dispute_id',
      minWidth: 100,
      Cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              <p>{row.original.dispute_id}</p>
            </Tooltip>
          }
        >
          <Link
            to={stripe_link + row.original.dispute_id}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium"
          >
            link
          </Link>
        </OverlayTrigger>
      )
    },
    {
      Header: 'Transaction ID',
      sortable: false,
      minWidth: 260,
      Cell: (row) => <div>{row?.original?.payment_intent}</div>
    },
    {
      Header: 'Reason',
      sortable: false,
      minWidth: 240,
      Cell: (row) => <div className="capitalize">{row?.original?.reason}</div>
    },
    {
      Header: 'Status',
      sortable: false,
      minWidth: 100,
      Cell: (row) => (
        <div>
          {row?.original?.status && (
            <div className="capitalize">{row?.original?.status.replaceAll('_', ' ')}</div>
          )}
        </div>
      )
    },
    {
      Header: 'Amount',
      sortable: false,
      minWidth: 100,
      Cell: (row) => <div>$ {(row?.original?.amount / 100).toFixed(2)}</div>
    },
    {
      sortable: false,
      Header: 'Payment Method',
      minWidth: 150,
      Cell: (row) =>
        row.original?.payment_details?.payment_method && (
          <div>
            {row.original?.payment_details?.payment_method == 'check'
              ? 'Check : **' + row.original?.payment_details?.ach_details?.account_last_4_digit
              : 'Card : **' + row.original?.payment_details?.card_details?.card_last_4_digit}
          </div>
        )
    },
    {
      Header: 'Respond By',
      sortable: false,
      minWidth: 200,
      Cell: (row) => {
        const isDate = JSON.parse(row?.original?.data)?.evidence_details?.due_by;
        if (!isDate || isDate == '0') return <></>;
        const dueDate = moment.unix(isDate);
        const formattedDueDate = dueDate.format('YYYY-MM-DD');
        const currentDate = moment();
        const daysRemaining = dueDate.diff(currentDate, 'days');
        return (
          <div className="flex justify-center items-center">
            {daysRemaining >= 0 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom">
                    <p>{daysRemaining} days to respond</p>
                  </Tooltip>
                }
              >
                <diV className="flex gap-2 justify-center items-center">
                  <span className="cursor-pointer">
                    <FcInfo size={20} />
                  </span>
                  <p>{formattedDueDate}</p>
                </diV>
              </OverlayTrigger>
            ) : (
              formattedDueDate
            )}
          </div>
        );
      }
    }
  ];

  const invoice_table_columns = [
    {
      Header: 'Actions',
      minWidth: 100,
      Cell: (row) => (
        <div className="flex gap-2 px-2 justify-center items-center">
          <div className="flex justify-center items-center">
            {HasPermission({ permission: ['delete_invoices'], require_all: false }) ? (
              <button
                className="text-red-500"
                onClick={() => _this.handleDeleteInvoices(row.original._id)}
              >
                <MdDelete size={24} />
              </button>
            ) : null}
          </div>

          {row?.original?.payment_gateway_response &&
            JSON.parse(row.original.payment_gateway_response)?.outcome?.seller_message && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom ">
                    <p>
                      {JSON.parse(row.original.payment_gateway_response)?.outcome?.seller_message}
                    </p>
                    <p></p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FcInfo size={20} />
                </span>
              </OverlayTrigger>
            )}
          {row.original.is_migrated_from_fusebill == true && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-bottom ">
                  <p>--Migrated--</p>
                  <p>Fusebill ID: {row.original.fusebill_id}</p>
                </Tooltip>
              }
            >
              <span className="cursor-pointer">
                <FcShipped size={20} />
              </span>
            </OverlayTrigger>
          )}
          <p>
            <Link
              to={`/view-invoice/${row.original._id}`}
              target="_blank"
              className="text-blue-500 underline"
            >
              {/* #{parseHsppId(row.original._id)} */}
              <TbFileInvoice size={24} />
            </Link>
          </p>
        </div>
      )
    },
    {
      Header: 'Transaction ID',
      minWidth: 260,
      Cell: (row) =>
        row.original.payment_gateway_transaction_id && (
          <div>{row.original.payment_gateway_transaction_id}</div>
        )
    },
    {
      Header: 'Payment Method',
      minWidth: 120,
      Cell: (row) =>
        row.original.payment_gateway_transaction_id === 'credit' ? (
          <div>Credits</div>
        ) : (
          row.original?.payment_details?.payment_method && (
            <div>
              {row.original?.payment_details?.payment_method == 'check'
                ? 'Check : **' + row.original?.payment_details?.ach_details?.account_last_4_digit
                : 'Card : **' + row.original?.payment_details?.card_details?.card_last_4_digit}
            </div>
          )
        )
    },
    {
      accessor: 'due_date',
      Header: 'Due Date',
      width: 120,
      Cell: (row) => (
        <div className="flex gap-2 justify-center">
          <span>{row.original.due_date && moment(row.original.due_date).format('YYYY-MM-DD')}</span>
          <PermissionWrapper permission={'edit_due_date'}>
            {row.original.status == 'Pending' && (
              <span>
                <BiEditAlt
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    _this.handleDueDateEdit(row.original._id, row.original.due_date);
                  }}
                />
              </span>
            )}
          </PermissionWrapper>
        </div>
      )
    },
    {
      accessor: 'paid_on',
      Header: 'Payment Process Date',
      Cell: (row) => (
        <div className="flex gap-2 justify-center">
          <span>
            {row.original.status === 'Refunded'
              ? moment(row?.original?.refunded_data[0]?.refunded_on).format('YYYY-MM-DD')
              : row.original.paid_on && moment(row.original.paid_on).format('YYYY-MM-DD')}
          </span>
        </div>
      )
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: (row) => <div>${row.original.amount}</div>
    },
    {
      accessor: 'status',
      Header: 'Status',
      minWidth: 150,
      Cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            row.original.status === 'Failed' ||
            row.original.status === 'Refunded' ||
            row.original.status === 'Paid' ? (
              <Tooltip id="tooltip-bottom ">{tooltipUI(row)}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              className={`font-semibold px-3 py-1 rounded text-xs ${renderStatusColorClass(
                row.original.status === 'Paid' || row.original.status === 'ChargebackWon'
                  ? 'Active'
                  : row.original.status === 'Cancel' ||
                    // row.original.status === 'Chargeback' ||
                    row.original.status === 'Refunded' ||
                    row.original.status === 'Void' ||
                    row.original.status === 'Failed' ||
                    row.original.status === 'ChargebackLost'
                  ? 'Cancelled'
                  : 'OnHold'
              )}`}
            >
              {row.original.status === 'Paid' ? (
                'PAID'
              ) : row.original.status === 'Failed' ? (
                'FAILED'
              ) : row.original.status === 'Void' ? (
                'CANCELLED'
              ) : row.original.status === 'Cancel' ? (
                'CANCELLED'
              ) : row.original.status === 'Processing' ? (
                'PROCESSING'
              ) : row.original.status === 'Refunded' ? (
                'REFUNDED'
              ) : row.original.status === 'Chargeback' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <IoMdTime size={18} />
                </div>
              ) : row.original.status === 'ChargebackWon' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaCheckCircle size={18} />
                </div>
              ) : row.original.status === 'ChargebackLost' ? (
                <div className="flex items-center justify-between">
                  <p>CHARGEBACK</p>
                  <FaTimes size={18} />
                </div>
              ) : (
                'PENDING'
              )}
            </div>
          )}
        </OverlayTrigger>
      )
    },
    {
      Cell: (row) => (
        <center>
          {row.original.status === 'Paid' ||
          row.original.status === 'Processing' ||
          row.original.status === 'ChargebackWon' ? (
            <PermissionWrapper permission="refund_invoices">
              <button
                className="bg-rose-700 hover:bg-rose-800 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
                onClick={() => _this.handleRefundDetails(row.original)}
              >
                Refund
              </button>
            </PermissionWrapper>
          ) : (
            row.original.status === 'Failed' && (
              <PermissionWrapper permission="pay_failed_invoices">
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
                  onClick={() => _this.handleRetryPayment(row.original._id)}
                >
                  Pay
                </button>
              </PermissionWrapper>
            )
          )}
        </center>
      ),
      Header: 'Refund/Pay'
    }
    // {
    //   accessor: 'action',
    //   Header: 'Bill',
    //   Cell: (row) => (
    //     <center>
    //       {row.original.status === 'Failed' ? (
    //         <PermissionWrapper permission="pay_failed_invoices">
    //           <button
    //             className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
    //             onClick={() => _this.handleRetryPayment(row.original._id)}
    //           >
    //             Pay
    //           </button>
    //         </PermissionWrapper>
    //       ) : row.original.status === 'Cancel' ? (
    //         ''
    //       ) : (
    //         <Link
    //           to={`/view-invoice/${row.original._id}`}
    //           target="_blank"
    //           className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
    //         >
    //           View Bill
    //         </Link>
    //       )}
    //     </center>
    //   )
    // }
  ];

  // if (HasPermission({ permission: ['pay_failed_invoices'], require_all: false }))
  //   invoice_table_columns.push({
  //     Cell: (row) => (
  //       <center>
  //         {row.original.status === 'Paid' || row.original.status === 'Processing' ? (
  //           <PermissionWrapper permission="refund_invoices">
  //             <button
  //               className="bg-rose-700 hover:bg-rose-800 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
  //               onClick={() => _this.handleRefundDetails(row.original)}
  //             >
  //               Refund
  //             </button>
  //           </PermissionWrapper>
  //         ) : (
  //           row.original.status === 'Failed' && (
  //             <PermissionWrapper permission="pay_failed_invoices">
  //               <button
  //                 className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
  //                 onClick={() => _this.handleRetryPayment(row.original._id)}
  //               >
  //                 Pay
  //               </button>
  //             </PermissionWrapper>
  //           )
  //         )}
  //       </center>
  //     ),
  //     Header: 'Refund/Pay'
  //   });

  if (HasPermission({ permission: ['refund_duplicate_invoices'], require_all: false }))
    invoice_table_columns.push({
      Cell: (row) => (
        <center>
          <button
            className="bg-rose-700 hover:bg-rose-800 text-white px-2 py-1 rounded text-xs flex flex-row self-center justify-center items-center"
            onClick={() => _this.handleRefundDuplicateInvoiceDetails(row.original)}
          >
            Refund
          </button>
        </center>
      ),
      Header: 'Refund Duplicate Invoices'
      // width: 200
    });

  // if (HasPermission({ permission: ['delete_invoices'], require_all: false }))
  //   invoice_table_columns.push({
  //     Cell: (row) => (
  //       <center>
  //         <button
  //           className="bg-rose-700 hover:bg-rose-800 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
  //           onClick={() => _this.handleDeleteInvoices(row.original._id)}
  //         >
  //           Delete
  //         </button>
  //       </center>
  //     ),
  //     Header: 'Delete'
  //   });
  return (
    <>
      {!checkIfAnySubscriptionActive() && (
        <Alert key={'danger'} variant={'danger'}>
          <strong>
            There are no active subscription available for this customer. Please add a subscription.
          </strong>
        </Alert>
      )}
      <Card background={'bg-cardbg1'} paddingClass="p-2">
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-20 w-full">
            <div className="flex gap-4 items-center">
              <div
                className="w-10 h-10 rounded-full border-2 border-gray-500 flex justify-center items-center text-gray-500 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <MdArrowBack size={40} />
              </div>
              <h3 className="text-body-color text-xl md:text-2xl">Customer Overview</h3>
            </div>
            {_this.isUploading ? (
              <div className="w-1/4 ml-10 text-center">
                <h2>Document upload in progress</h2>
                <ProgressBar now={100} animated />
              </div>
            ) : null}
          </div>
          {customer?.status && customer?.status != 'Active' ? (
            <div className="">
              <div className="flex flex-row items-center justify-between">
                <span
                  className={`${renderStatusColorClass(
                    customer?.status === 'Active' ? 'Active' : 'Cancelled'
                  )} font-semibold px-3 py-1 rounded text-xs`}
                >
                  <span className="font-semibold text-base">{customer?.status}</span>
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card>
      <div className="flex flex-col md:flex-row">
        <Card className="flex basis-1/2 mr-2 mt-2">
          <PermissionWrapper permission="view_customer_details">
            <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
              <h3 className="text-body-color text-xl font-semibold">
                Customer | {customer?.first_name + ' ' + customer?.last_name}
              </h3>
              <PermissionWrapper permission="edit_customer_details">
                <button
                  onClick={() => {
                    _this.setEditCustomerModalOpen(true);
                  }}
                  className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                >
                  <AiOutlineSetting className="text-white" size={25} />
                  <span className="font-medium text-base ml-0.5 text-white">Edit</span>
                </button>
              </PermissionWrapper>
            </div>
            <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
              Contact Details
            </h6>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">HSPP ID:</h5>
              <h5 className="text-slate-500 text-sm">{customer?.hspp_id}</h5>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Patient ID:</h5>
              <div className="flex gap-2">
                <a
                  href={`https://www.mymiracle-ear.com/freecvs/patient_summary.php?simple_close=1&patient_id=${customer?.customer_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaRecycle />
                </a>
                <h5 className="text-slate-500 text-sm">{customer?.customer_id}</h5>
              </div>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Name:</h5>
              <h5 className="text-slate-500 text-sm">
                {customer?.first_name + ' ' + customer?.last_name}
              </h5>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Phone:</h5>
              <h5 className="text-slate-500 text-sm">{customer?.phone}</h5>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Email:</h5>
              <h5 className="text-slate-500 text-sm">{customer?.email}</h5>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Enabled Email Updates:</h5>
              <h5 className="text-slate-500 text-sm">{customer?.enableEmails ? 'Yes' : 'No'}</h5>
            </div>
            <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
              <h5 className="text-slate-500 text-sm">Signature:</h5>
              <h5 className="text-slate-500 text-sm signature">{customer?.signature?.signature}</h5>
            </div>
            <PermissionWrapper permission={'manage_customer_document'}>
              <div className="mt-3 flex justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Documents:</h5>
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded-md"
                  onClick={() => {
                    _this.setChatModalOpen(true);
                  }}
                  disabled={!_this.documents}
                >
                  {!_this.documents && <Spinner animation="border" size="sm" />} Manage Documents
                </button>
              </div>
            </PermissionWrapper>
          </PermissionWrapper>
        </Card>
        <Card className="flex basis-1/2 mt-2">
          <PermissionWrapper permission={'view_shipping_details'}>
            <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
              <h3 className="text-body-color text-xl font-semibold pb-2">
                Shipping & Billing Information
              </h3>
              <PermissionWrapper permission={'edit_shipping_details'}>
                <button
                  onClick={() => {
                    _this.setEditCustomerModalOpen(true);
                  }}
                  className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                >
                  <AiOutlineSetting className="text-white" size={25} />
                  <span className="font-medium text-base ml-0.5 text-white">Edit</span>
                </button>
              </PermissionWrapper>
            </div>
            <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
              Shipping Address
            </h6>
            <div className="text-md text-slate-500 mt-1 w-[300px]">
              {customer?.shipping_address.contact}
              <br />
              {customer?.shipping_address.address1 +
                ', ' +
                (customer?.shipping_address.address2
                  ? customer.shipping_address.address2 + ', '
                  : '') +
                customer?.shipping_address.city +
                ', ' +
                customer?.shipping_address.state +
                ' ' +
                customer?.shipping_address.zipcode +
                ', ' +
                customer?.shipping_address.country}
            </div>
            <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
              Billing Address
            </h6>
            <div className="text-md text-slate-500 mt-1 w-[300px]">
              {customer?.billing_address.address1 +
                ', ' +
                (customer?.billing_address.address2
                  ? customer.billing_address.address2 + ', '
                  : '') +
                customer?.billing_address.city +
                ', ' +
                customer?.billing_address.state +
                ' ' +
                customer?.billing_address.zipcode +
                ', ' +
                customer?.billing_address.country}
            </div>
          </PermissionWrapper>
        </Card>
      </div>

      <div className="flex flex-col lg:flex-row mt-2 ">
        <div className="flex basis-1/3 mr-2 flex-col gap-2 h-full">
          <Card className=" ">
            <PermissionWrapper permission={'view_credits'}>
              <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
                <h3 className="text-body-color text-xl font-semibold  pb-2">Credits</h3>
                <PermissionWrapper permission={'add_credits'}>
                  <button
                    onClick={() => {
                      _this.setCreditModelOpen(true);
                    }}
                    className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <BiPlus className="text-white" size={23} />
                    <span className="font-medium text-base ml-0.5 text-white">Add / Remove</span>
                  </button>
                </PermissionWrapper>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Credit Amount :</h5>
                <h5 className="text-slate-500 text-sm">
                  $ {customer?.credit?.credit_amount?.toFixed(2) || '0.00'}
                </h5>
              </div>
            </PermissionWrapper>
          </Card>
          <Card className="  ">
            <PermissionWrapper permission={'view_payment_method'}>
              <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
                <h3 className="text-body-color text-xl font-semibold  pb-2">Payment Method</h3>
                <PermissionWrapper permission={'add_payment_method'}>
                  <button
                    onClick={() => {
                      _this.setAddPaymentModalOpen(true);
                    }}
                    className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <BiPlus className="text-white" size={23} />
                    <span className="font-medium text-base ml-0.5 text-white">Add</span>
                  </button>
                </PermissionWrapper>
              </div>

              {customer?.payment_method ? (
                <>
                  {customer?.payment_method?.map((item, index) => (
                    <div
                      onClick={() => {
                        if (
                          !_this.loggedInUser?.permissions?.includes('set_primary_payment_method')
                        )
                          return false;
                        if (item.is_primary == false) {
                          _this.setSelectPrimaryPaymentModalOpen(true);
                          item.payment_method == 'creditcard'
                            ? _this.setSelectedPaymentDetails((prev) => ({
                                ...prev,
                                payment_method_id: item._id,
                                customer_id: item.customer,
                                card_number: item.card_details.card_last_4_digit,
                                payment_method: item.payment_method
                              }))
                            : _this.setSelectedPaymentDetails((prev) => ({
                                ...prev,
                                payment_method_id: item._id,
                                customer_id: item.customer,
                                card_number: item.ach_details.account_last_4_digit,
                                payment_method: item.payment_method
                              }));
                        }
                      }}
                      key={index}
                      className={`p-3 mt-3 flex flex-col justify-center rounded-lg items-end border-2 border-blue-500 ${
                        item.is_primary == false && 'border'
                      } `}
                    >
                      <div className="flex justify-between items-center w-full mb-1">
                        <p className="text-sm">Payment Type:</p>
                        <p className="text-body-color text-sm">
                          {item.payment_method === 'creditcard' ? 'Credit Card' : 'ACH'}
                        </p>
                      </div>
                      {item.payment_method == 'creditcard' ? (
                        <>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Name:</p>
                            <p className="text-body-color text-sm">{item.card_details.name}</p>
                          </div>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Expiry Date:</p>
                            <p className="text-body-color text-sm">
                              {item.card_details.expiry_date}
                            </p>
                          </div>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Card Number:</p>
                            <p className="text-body-color text-sm">
                              XXXX XXXX XXXX {item.card_details.card_last_4_digit}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Name:</p>
                            <p className="text-body-color text-sm">{item.ach_details.name}</p>
                          </div>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Account Number:</p>
                            <p className="text-body-color text-sm">
                              XXXX XXXX XXXX {item.ach_details.account_last_4_digit}
                            </p>
                          </div>
                        </>
                      )}
                      <div
                        className={`flex items-center w-full mb-1 ${
                          item.is_primary == true ? 'justify-between' : 'justify-start'
                        }`}
                      >
                        <div className="flex">
                          <PermissionWrapper permission={'delete_payment_method'}>
                            <MdDelete
                              onClick={(e) => {
                                e.stopPropagation();
                                if (item.is_primary == true)
                                  return toast.error(
                                    'Cannot delete this primary payment method. Select another payment method as primary or add one to delete this payment method'
                                  );
                                _this.setDeletePaymentMethodModalOpen(true);

                                _this.setDeletePaymentMethodData((prev) => ({
                                  ...prev,
                                  _id: item._id,
                                  last_4_digit:
                                    item.payment_method == 'creditcard'
                                      ? item.card_details.card_last_4_digit
                                      : item.ach_details.account_last_4_digit
                                }));
                              }}
                              fill="red"
                              size={20}
                              className="cursor-pointer"
                            />
                          </PermissionWrapper>

                          {item.is_migrated_from_fusebill == true && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-bottom ">
                                  <p>--Migrated--</p>
                                  <p>Fusebill ID: {item.fusebill_id}</p>
                                </Tooltip>
                              }
                            >
                              <span className="cursor-pointer">
                                <FcShipped size={20} />
                              </span>
                            </OverlayTrigger>
                          )}
                        </div>
                        {item.is_primary === true && (
                          <p className="text-button-primaryHover font-semibold text-sm mt-2">
                            Primary
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="pb-1 mt-3 flex flex-row justify-between rounded-lg items-center border ">
                  <div className="flex justify-center p-10">
                    <div className="text-md text-slate-500 mt-1 w-[300px]">
                      No Payment Method Available.
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-between items-center border-b-[2px] pb-1 mt-3">
                <h6 className="text-body-color font-medium ">Billing Address</h6>
                <PermissionWrapper permission={'edit_billing_address'}>
                  <button
                    onClick={() => {
                      _this.setEditPaymentModalOpen(true);
                    }}
                    className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <AiOutlineSetting className="text-white" size={20} />
                    <span className="font-medium text-sm ml-0.5 text-white">Edit</span>
                  </button>
                </PermissionWrapper>
              </div>
              <div className="text-md text-slate-500 mt-1 w-[300px]">
                {customer.billing_address.address1 +
                  ', ' +
                  (customer.billing_address.address2
                    ? customer.billing_address.address2 + ', '
                    : '') +
                  customer.billing_address.city +
                  ', ' +
                  customer.billing_address.state +
                  ' ' +
                  customer.billing_address.zipcode +
                  ', ' +
                  customer.billing_address.country}
              </div>
            </PermissionWrapper>
          </Card>
        </div>
        <Card className="flex basis-2/3 ">
          <PermissionWrapper permission={'view_subscription'}>
            <div className="border-b-[3px] pb-2 flex flex-row justify-between items-center">
              <h3 className="text-body-color text-xl font-semibold">
                Subscriptions ({customer.subscription_groups.length})
              </h3>
              <PermissionWrapper permission={'add_subscription'}>
                <button
                  onClick={() => {
                    _this.setAddNewSubscriptionModalOpen(true);
                  }}
                  className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                >
                  <BiPlus className="text-white" size={23} />
                  <span className="font-medium text-base ml-0.5 text-white">Add</span>
                </button>
              </PermissionWrapper>
            </div>
            {customer.subscription_groups.map((subscription_group, index) => {
              let firstSubscription = subscription_group?.subscriptions?.find(
                (sub) => sub.status == 'Active'
              );

              if (!firstSubscription) firstSubscription = subscription_group?.subscriptions[0];
              return (
                <div key={index}>
                  <div className="flex flex-row justify-between items-center border-b-[2px] pb-2 mt-4">
                    <h4 className="text-body-color font-bold sm:text-sm text-xs">
                      Subscription - {index + 1}
                    </h4>

                    <div className="flex flex-col md:flex-row justify-center items-end gap-2">
                      <div className="flex lg:flex-col xl:flex-row gap-2">
                        <div className="font-semibold bg-amber-300 text-amber-700 sm:text-sm rounded-md text-xs flex justify-center items-center py-1 px-1 sm:px-2">
                          Expiry Date :{' '}
                          {moment(firstSubscription?.expiry_date).format('YYYY-MM-DD')}
                        </div>
                        <div className="font-semibold bg-teal-300 text-teal-700 sm:text-sm rounded-md text-xs flex justify-center items-center py-1 px-1 sm:px-2">
                          Next Invoice :{' '}
                          {moment(firstSubscription?.next_invoice_date).format('YYYY-MM-DD')}
                        </div>
                      </div>
                      <PermissionWrapper permission={'edit_subscription'}>
                        <button
                          onClick={() => {
                            // console.log(
                            //   _this.customerDetails.subscription_groups[index].subscriptions[0]
                            // );
                            _this.setEditSubscriptionModalOpen(true);
                            const tempData =
                              _this.customerDetails.subscription_groups[index].subscriptions[0]
                                .product_information;

                            const chargable =
                              tempData.chargable == 'No'
                                ? { label: 'No', value: 'No' }
                                : { label: 'Yes', value: 'Yes' };
                            const location = {
                              label: tempData.location_name,
                              value: {
                                locationId: tempData.location_id,
                                franchiseName: tempData.franchise_name,
                                franchiseId: tempData.franchise_id,
                                locationGroup: tempData.location_group,
                                l_battery_size: tempData.l_battery_size,
                                r_battery_size: tempData.r_battery_size
                              }
                            };
                            _this.setSelectedSubscriptionDataForEdit({
                              chargable,
                              location,
                              l_battery_size: {
                                label: tempData.l_battery_size,
                                value: tempData.l_battery_size
                              },
                              r_battery_size: {
                                label: tempData.r_battery_size,
                                value: tempData.r_battery_size
                              },
                              right_serial: tempData.right_serial || '',
                              left_serial: tempData.left_serial || '',
                              id: _this.customerDetails.subscription_groups[
                                index
                              ].subscriptions.map((item) => item._id),
                              customer: _this.customerDetails._id,
                              newsletter:
                                _this.customerDetails.subscription_groups[index].subscriptions[0]
                                  .newsletter
                            });
                          }}
                          className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                        >
                          <AiOutlineSetting className="text-white" size={20} />
                          <span className="font-medium text-sm ml-0.5 text-white">Edit</span>
                        </button>
                      </PermissionWrapper>
                    </div>
                    {/* <button
                    onClick={() => {
                      _this?.setSubscriptionIdforPlan(firstSubscription?._id);
                      _this.setAddNewPlanModalOpen(true);
                    }}
                    className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <h6 className="text-white text-sm">Add New Plan</h6>
                  </button> */}
                  </div>
                  <div className="flex flex-col border-b-[2px]">
                    <div className=" basis-1/2">
                      <div className="flex flex-col sm:flex-row mt-3 text-xs sm:text-sm text-body-color gap-2">
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Store: </span>{' '}
                          {firstSubscription?.product_information.location_name}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Chargeable: </span>{' '}
                          {firstSubscription?.product_information.chargable}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">L Warranty: </span>{' '}
                          {moment(firstSubscription?.l_warranty_expiry).format('YYYY-MM-DD')}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row mt-1 text-xs sm:text-sm text-body-color gap-2">
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Left Serial: </span>{' '}
                          {firstSubscription?.product_information.left_serial || 'N/A'}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Right Serial: </span>{' '}
                          {firstSubscription?.product_information.right_serial || 'N/A'}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">R Warranty: </span>{' '}
                          {moment(firstSubscription?.r_warranty_expiry).format('YYYY-MM-DD')}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row mt-1 text-xs sm:text-sm text-body-color gap-2">
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">L Battery Size: </span>{' '}
                          {firstSubscription?.product_information.l_battery_size || 'N/A'}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">R Battery Size: </span>{' '}
                          {firstSubscription?.product_information.r_battery_size || 'N/A'}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Last Invoice Date: </span>{' '}
                          {moment(firstSubscription?.active_invoice_date).format('YYYY-MM-DD')}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row mt-1 text-xs sm:text-sm text-body-color gap-2">
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Do not mail: </span>
                          {customer?.shipping_address?.do_not_mail ? 'Yes' : 'No'}
                        </div>
                        <div className="flex basis-1/2">
                          <span className="font-semibold mr-2">Newsletter: </span>
                          {firstSubscription?.newsletter ? 'Yes' : 'No'}
                        </div>
                        <div className="flex basis-1/2">
                          {firstSubscription?.plan_override_amount &&
                          moment(firstSubscription?.plan_override_validity) > moment() ? (
                            <span className="font-semibold mr-2">
                              Amount after discount : {firstSubscription?.plan_override_amount}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="flex flex-col sm:flex-row mt-1 text-xs sm:text-sm text-body-color gap-2">
                        <div className="flex basis-1/2">
                          <Checkbox
                            className="text-sm"
                            id="cc"
                            label={<p className="font-semibold text-black">Do not mail</p>}
                            type="checkbox"
                            checked={customer?.shipping_address?.do_not_mail}
                          />}
                          <p className="font-semibold text-black">
                            Do not mail:
                            {customer?.shipping_address?.do_not_mail ? 'Yes' : 'No'}
                          </p>
                        </div>
                        <div className="flex basis-1/2">
                          <Checkbox
                            className="text-sm"
                            id="cc"
                            label={<p className="font-semibold text-black">Newsletter</p>}
                            type="checkbox"
                            checked={firstSubscription?.newsletter}
                          />
                        </div>
                        <div className="flex basis-1/2"></div>
                      </div> */}
                    </div>
                    {/* <div className="font-semibold mt-4 text-body-color">Plans </div> */}
                    <div className="pb-2 mt-2">
                      <table className="w-full">
                        <thead>
                          <tr className="bg-blue-100 p-2">
                            <th>Actions</th>
                            <th>Plan Name</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        {/* <div>
                          <h3 className="border-r sm:px-4 px-1 py-1 sm:py-2 text-sm sm:text-lg">
                            Plan Name
                          </h3>
                          <h3 className="sm:px-4 px-1 py-1 sm:py-2 border-r text-sm sm:text-lg text-center">
                            Price
                          </h3>
                          <h3 className="border-r sm:px-4 px-1 py-1 sm:py-2 text-sm sm:text-lg text-center">
                            Status
                          </h3>
                          <h3 className="sm:px-4 px-1 py-1 sm:py-2 text-sm sm:text-lg text-center">
                            Action
                          </h3>
                        </div> */}
                        <tbody>
                          {subscription_group?.subscriptions.map((subscription, subIndex) => {
                            return (
                              <tr key={subIndex}>
                                <td>
                                  <div className="flex gap-2 px-1 sm:px-4 py-1 sm:py-2 text-base border-r border-l-none ">
                                    {subscription.status != 'Expired' &&
                                    subscription.status != 'Cancelled' ? (
                                      <PermissionWrapper permission={'pay_extra'}>
                                        <FaPaypal
                                          onClick={() => {
                                            _this.setPayModal(true);
                                            _this.setPayAdvanceSubscriptionData((prev) => ({
                                              ...prev,
                                              _id: subscription?._id,
                                              plan_name: subscription?.plan_name,
                                              expiry_date: subscription?.expiry_date,
                                              active_invoice_date:
                                                subscription?.active_invoice_date,
                                              next_invoice_date: subscription?.next_invoice_date,
                                              plan_interval: subscription?.plan_interval,
                                              amount: subscription?.amount
                                            }));
                                          }}
                                          fill="green"
                                          size={25}
                                          className="cursor-pointer"
                                        />
                                      </PermissionWrapper>
                                    ) : (
                                      ''
                                    )}
                                    <PermissionWrapper permission={'delete_subscription'}>
                                      <MdDelete
                                        onClick={() => {
                                          _this.setDeleteSubscriptionModalOpen(true);

                                          _this.setDeleteSubscriptionData((prev) => ({
                                            ...prev,
                                            _id: subscription._id,
                                            plan_name: subscription.plan_name
                                          }));
                                        }}
                                        fill="red"
                                        size={25}
                                        className="cursor-pointer"
                                      />
                                    </PermissionWrapper>

                                    <PermissionWrapper permission={'temporary_edit_subscription'}>
                                      <BiEditAlt
                                        size={25}
                                        onClick={() => {
                                          _this.setTemporaryEditSubscriptionModalOpen(true);

                                          _this.setTemporaryEditSubscriptionData((prev) => ({
                                            ...prev,
                                            _id: subscription._id,
                                            l_warranty_expiry:
                                              subscription.l_warranty_expiry == 'NA' ||
                                              subscription.l_warranty_expiry == null
                                                ? ''
                                                : moment(subscription.l_warranty_expiry).toDate(),
                                            r_warranty_expiry:
                                              subscription.r_warranty_expiry == 'NA' ||
                                              subscription.r_warranty_expiry == null
                                                ? ''
                                                : moment(subscription.r_warranty_expiry).toDate(),
                                            next_invoice_date:
                                              subscription.next_invoice_date == null ||
                                              subscription.next_invoice_date == 'NA'
                                                ? ''
                                                : moment(subscription.next_invoice_date).toDate(),
                                            expiry_date:
                                              subscription.expiry_date == 'NA' ||
                                              subscription.expiry_date == null
                                                ? ''
                                                : moment(subscription.expiry_date).toDate(),
                                            status: {
                                              label: subscription.status,
                                              value: subscription.status
                                            }
                                          }));
                                        }}
                                        className="cursor-pointer text-blue-600"
                                      />
                                    </PermissionWrapper>

                                    {subscription.is_migrated_from_fusebill == true && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-bottom ">
                                            <p>--Migrated--</p>
                                            <p>Fusebill ID: {subscription.fusebill_id}</p>
                                          </Tooltip>
                                        }
                                      >
                                        <span className="cursor-pointer">
                                          <FcShipped size={20} />
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <p className="max-w-[10rem] md:max-w-none text-xs md:text-lg border-r border-l-none ">
                                    {subscription?.plan_name}
                                  </p>
                                </td>
                                <td>
                                  <div className=" px-1 sm:px-4 py-1 sm:py-2 border-r text-xs sm:text-base text-center">
                                    ${subscription?.price}
                                  </div>
                                </td>
                                <td>
                                  <div className=" border-r text px-1 py-1 sm:py-2 text-sm sm:text-xs flex justify-center items-center">
                                    <div
                                      className={`${renderStatusColorClass(
                                        subscription?.status === 'Active'
                                          ? 'Active'
                                          : subscription?.status === 'Expired'
                                          ? 'Expired'
                                          : subscription?.status === 'Cancelled'
                                          ? 'Cancelled'
                                          : 'OnHold'
                                      )} sm:font-semibold font-thin px-1 sm:px-2 rounded w-12 sm:w-20 ml-2 text-center`}
                                    >
                                      <span className="sm:font-semibold font-thin text-xs sm:text-sm">
                                        {subscription?.status}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className=" px-1 sm:px-4 py-1 sm:py-2 text-sm border-r flex justify-center align-center">
                                    <button
                                      onClick={() => {
                                        _this.setSubscriptionDetails(subscription);
                                        _this.setSubscriptionDetailsModalOpen(true);
                                      }}
                                      className="bg-blue-500 text-green-700 px-1 py-1 rounded text-xs flex flex-row"
                                    >
                                      <h6 className="text-white text-xs">View Details</h6>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </PermissionWrapper>
        </Card>
      </div>

      <Card className="flex mr-2 mt-2">
        <PermissionWrapper permission={'view_invoices'}>
          <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">Invoices</h3>
          <Table
            keyField="_id"
            columns={invoice_table_columns}
            data={customer.invoices}
            headerComponent={null}
            minRows={10}
          />
        </PermissionWrapper>
      </Card>
      <div className="mt-2 flex flex-col md:flex-row gap-2">
        <Card className="flex basis-1/3">
          <PermissionWrapper permission={'view_notes'}>
            <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
              <h3 className="text-body-color text-xl font-semibold">Notes</h3>
              <PermissionWrapper permission={'add_note'}>
                <button
                  onClick={() => {
                    _this.setAddNoteModalOpen(true);
                  }}
                  className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                >
                  <span className="font-medium text-base ml-0.5 text-white">Add Note</span>
                </button>
              </PermissionWrapper>
            </div>
            {customer?.notes.map((e, i) => (
              <div
                key={i}
                className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2"
              >
                <h5 className="text-slate-500 text-sm">{e.note}</h5>
                <h5 className="text-slate-500 text-sm">{e.by}</h5>
              </div>
            ))}
          </PermissionWrapper>
        </Card>
        <Card className="flex basis-2/3">
          <PermissionWrapper permission={'view_logs'}>
            <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">Logs</h3>
            <Table
              keyField="id"
              columns={logs_table_columns}
              data={customer.logs}
              headerComponent={null}
              minRows={5}
              defaultPageSize={5}
            />
          </PermissionWrapper>
        </Card>
      </div>

      <PermissionWrapper permission={'view_chargeback'}>
        <Card className="mt-2">
          <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
            <h3 className="text-body-color text-xl font-semibold  pb-2">Chargeback</h3>
          </div>
          <Table
            keyField="id"
            columns={chargeback_table}
            data={customer?.chargeback}
            headerComponent={null}
            minRows={5}
            defaultPageSize={5}
          />
        </Card>
      </PermissionWrapper>
      <EditCustomer _this={_this} />
      <AddNewPayment _this={_this} />
      <EditPaymentDetails _this={_this} />
      <EditSubscription _this={_this} />
      <AddNote _this={_this} />
      <SubscriptionDetails _this={_this} />
      <AddNewPlan _this={_this} />
      <AddNewSubscription _this={_this} />
      <SetPrimaryPaymentModal _this={_this} />
      <RefundModal _this={_this} />
      <DocumentModel _this={_this} />
      <AddCredit _this={_this} />
      <RefundDuplicateInvoiceModal _this={_this} />
      <TemporaryEditSubscription _this={_this} />
      <DeleteSubscriptionModal _this={_this} />
      <DeleteInvoiceModal _this={_this} />
      <EditDueDateModal _this={_this} />
      <PayExtra _this={_this} />
      <SeeLogEmailDataModal _this={_this} />
      <DeletePaymentMethodModal _this={_this} />
      <LogDataModal _this={_this} />
      <DeleteDocumentModal _this={_this} />
    </>
  );
};

export default Section;
